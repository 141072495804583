import React, { useState } from 'react';

import { Accordion, AccordionBody, AccordionHeader } from '../../components/Common/Accordion';

export default function Faq() {
  const [open, setOpen] = useState(1);
 
  const handleOpen = (value: number) => {
    setOpen(open === value ? 0 : value);
  };
  
  return (
    <section className="bg-white py-3 lg:py-14 dark:bg-gradient-to-t dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="container px-4 py-4 mx-auto">
        <h2 className="text-xl md:text-2xl font-bold lg:text-4xl lg:max-w-xl lg:mx-auto text-center mb-10 dark:text-white">
          Frequently Asked Questions
        </h2>
        <div>
          <Accordion>
            <AccordionHeader
              onPress={ () => handleOpen(1) }  
              open={ open === 1 }
            >
              How player tokenisation works ?
            </AccordionHeader>
            <AccordionBody open={ open === 1 }>
              <p className="dark:text-white">
                Player tokenization is a process that transforms unique moments and achievements of football players into digital collectibles, known as Non-Fungible Tokens (NFTs). These NFTs can be bought, sold, and traded on the blockchain, giving collectors the ability to own a piece of history and prove their ownership. Each player token is unique and cannot be replicated, adding value to the collectible. With player tokenization, you can own a piece of your favorite football player&apos;s legacy and be a part of the future of football collecting.
              </p>
            </AccordionBody>
          </Accordion>
          <Accordion>
            <AccordionHeader
              onPress={ () => handleOpen(2) }  
              open={ open === 2 }
            >
              How does the sale process work ?
            </AccordionHeader>
            <AccordionBody open={ open === 2 }>
              <p className="dark:text-white">
                Player tokenization is a process that transforms unique moments and achievements of football players into digital collectibles, known as Non-Fungible Tokens (NFTs). These NFTs can be bought, sold, and traded on the blockchain, giving collectors the ability to own a piece of history and prove their ownership. Each player token is unique and cannot be replicated, adding value to the collectible. With player tokenization, you can own a piece of your favorite football player&apos;s legacy and be a part of the future of football collecting.
              </p>
            </AccordionBody>
          </Accordion>
          <Accordion>
            <AccordionHeader
              onPress={ () => handleOpen(3) }  
              open={ open === 3 }
            >
              What&apos;s a royalty player ?
            </AccordionHeader>
            <AccordionBody open={ open === 3 }>
              <p className="dark:text-white">
                Player tokenization is a process that transforms unique moments and achievements of football players into digital collectibles, known as Non-Fungible Tokens (NFTs). These NFTs can be bought, sold, and traded on the blockchain, giving collectors the ability to own a piece of history and prove their ownership. Each player token is unique and cannot be replicated, adding value to the collectible. With player tokenization, you can own a piece of your favorite football player&apos;s legacy and be a part of the future of football collecting.
              </p>
            </AccordionBody>
          </Accordion>
        </div>
      </div>
    </section>
  );
};
