// @ts-ignore
import _ from 'lodash';
// @ts-ignore
import Validator from 'validator';

import { passwordRegEx } from '../app/constants';
import { RegisterRequestType } from '../models/Auth';
import { EMAIL_NOT_VALID, PASSWORD_MIN, PASSWORD_NOT_VALID,REQUIRED, USERNAME_NOT_VALID } from './messages';


export default function validateInput(data: RegisterRequestType) {
  const errors = {
    name: '',
    username: '',
    email: '',
    password: ''
  };

  if (Validator.isEmpty(data.name)) {
    errors.name = REQUIRED;
  }

  if (!/^[A-Za-z0-9_.]+$/.test(data.username)) {
    errors.username = USERNAME_NOT_VALID;
  }

  if (Validator.isEmpty(data.username)) {
    errors.username = REQUIRED;
  }
  
  if (!Validator.isEmail(data.email)) {
    errors.email = EMAIL_NOT_VALID;
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = REQUIRED;
  }

  if (data.password.length<8) {
    errors.password = PASSWORD_MIN;
  }

  if (!passwordRegEx.test(data.password)) {
    errors.password = PASSWORD_NOT_VALID;
  }

  if (Validator.isEmpty(data.password)) {
    errors.password = REQUIRED;
  }

  return {
    errors,
    isValid: _.values(errors).every(_.isEmpty)
  };
}
