import coinbaseModule from '@web3-onboard/coinbase';
import dcentModule from '@web3-onboard/dcent';
import fortmaticModule from '@web3-onboard/fortmatic';
import frontierModule from '@web3-onboard/frontier';
import gnosisModule from '@web3-onboard/gnosis';
import infinityWalletModule from '@web3-onboard/infinity-wallet';
import injectedModule from '@web3-onboard/injected-wallets';
import keepkeyModule from '@web3-onboard/keepkey';
import keystoneModule from '@web3-onboard/keystone';
import ledgerModule from '@web3-onboard/ledger';
import magicModule from '@web3-onboard/magic';
import portisModule from '@web3-onboard/portis';
import { init } from '@web3-onboard/react';
import sequenceModule from '@web3-onboard/sequence';
import tahoModule from '@web3-onboard/taho';
import trustModule from '@web3-onboard/trust';
import walletConnectModule from '@web3-onboard/walletconnect';

import GoallLogo from '../assets/img/logo.svg';
import { INFURA_KEY } from '../config';

const injected = injectedModule();
const coinbase = coinbaseModule();
const dcent = dcentModule();
const walletConnect = walletConnectModule();

const portis = portisModule({
  apiKey: 'apiKey'
});

const fortmatic = fortmaticModule({
  apiKey: 'apiKey'
});

const infinityWallet = infinityWalletModule();
const ledger = ledgerModule();
const keystone = keystoneModule();
const keepkey = keepkeyModule();
const gnosis = gnosisModule();
const sequence = sequenceModule();
const taho = tahoModule(); // Previously named Tally Ho wallet
const trust = trustModule();
const frontier = frontierModule();

const magic = magicModule({
  apiKey: 'apiKey'
});

const wallets = [
  injected,
  coinbase,
  trust,
  walletConnect,
  magic,
  infinityWallet,
  fortmatic,
  ledger,
  keepkey,
  sequence,
  frontier,
  taho,
  dcent,
  gnosis,
  keystone,
  portis
];

const chains = [
  {
    id: '0x1',
    token: 'ETH',
    label: 'Ethereum Mainnet',
    rpcUrl: `https://mainnet.infura.io/v3/${INFURA_KEY}`
  },
  {
    id: '0x5',
    token: 'ETH',
    label: 'Goerli',
    rpcUrl: `https://goerli.infura.io/v3/${INFURA_KEY}`
  },
  {
    id: '0x13881',
    token: 'MATIC',
    label: 'Polygon - Mumbai',
    rpcUrl: 'https://matic-mumbai.chainstacklabs.com'
  },
  {
    id: '0x38',
    token: 'BNB',
    label: 'Binance',
    rpcUrl: 'https://bsc-dataseed.binance.org/'
  },
  {
    id: '0xA',
    token: 'OETH',
    label: 'Optimism',
    rpcUrl: 'https://mainnet.optimism.io'
  },
  {
    id: '0xA4B1',
    token: 'ARB-ETH',
    label: 'Arbitrum',
    rpcUrl: 'https://rpc.ankr.com/arbitrum'
  }
];

export const web3Onboard = init({
  wallets,
  chains,
  connect: {
    autoConnectLastWallet: true
  },
  appMetadata: {
    name: 'Goall connect wallet',
    icon: GoallLogo,
    description: 'Goall web3 onboarding.'
  },
  accountCenter: {
    desktop: {
      position: 'bottomRight',
      enabled: true,
      minimal: false
    },
    mobile: {
      position: 'bottomRight',
      enabled: true,
      minimal: false
    }
  },
});
