import React from 'react';

import Navigation from './routes';

function App() {
  return (
    <Navigation />
  );
}

export default App;
