import { Web3OnboardProvider } from '@web3-onboard/react';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import App from '../App';
import { web3Onboard } from '../app/web3Onboard';
import { AuthProvider } from './auth/AuthProvider';
import { ThemeProvider } from './ThemeProvider';

export const Providers: React.FC = () => {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <AuthProvider>
          <Web3OnboardProvider web3Onboard={ web3Onboard }>
            <App />
          </Web3OnboardProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};
