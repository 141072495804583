import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BlogAPI } from '../../app/api/endpoints/Blog';
import Bg from '../../assets/img/bg/green_grad.png';
import LatestPosts from '../../components/Blog/LatestPosts';
import Empty from '../../components/Common/Empty';
import CardPlaceholder from '../../components/Common/Skeleton/CardPlaceholder';
import { errorAlert, handleError } from '../../helpers/Utils';
import { Post } from '../../models/Blog';
import { useTheme } from '../../providers/ThemeProvider';

export default function BlogDetails() {
  const { theme } = useTheme();
  const { postSlug } = useParams<{ postSlug: string}>();
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState<Post>();
  const [latestPosts, setLatestPosts] = useState<Post[]>([]);

  useEffect(() => {
    if(postSlug) {
      getPost();
    }
  }, [ postSlug ]);

  const getPost = async () => {
    setLoading(true);
    const { result } = await BlogAPI.getPost(postSlug, (error) => {
      setLoading(false);
      errorAlert(handleError(error));
    });

    if (result) {
      setLoading(false);
      if (result.status) {
        setPost(result.post);
        setLatestPosts(result.latest_posts);
      }
    }
  };

  return (
    <section className="bg-white py-4 lg:py-24 relative bg-left-top bg-no-repeat dark:bg-gradient-to-br dark:from-indigo2 dark:via-indigo2 dark:to-indigo3"
      style={{ backgroundImage: theme === 'light' ? `url(${Bg})` : '' }}
    >
      <div className="container px-4 mx-auto">
        <h2 className="font-bold text-2xl lg:text-3xl mt-14 lg:mt-8 mb-8 lg:mb-10 dark:text-white">
          Blog <span className="text-sm text-gray-500 font-normal">/details</span>
        </h2>
        {
          loading ? <CardPlaceholder lines={ 10 } widthClass="w-full" /> : (
            post ? (
              <div>
                <h2 className="font-bold text-3xl lg:text-4xl mt-14 lg:mt-8 mb-8 lg:mb-10 max-w-4xl dark:text-white">
                  { post?.title }
                </h2>
                <img className="w-full rounded-lg" src={ post?.featured_image } alt={ post?.title } />
                <div className="mt-6" dangerouslySetInnerHTML={{ __html: post?.body }} />
              </div>
            ) : (
              <Empty />
            )
          )
        }
        <div className="mt-14">
          <LatestPosts posts={ latestPosts }/>
        </div>
      </div>
    </section>
  );
};
