import React from 'react';

import iPhoneMokup from '../../assets/img/home/iPhone_12_mokup.png';
import MacBookPro from '../../assets/img/home/MacBookPro.png';

export default function FutureSection() {
  return (
    <section className="bg-white py-3 lg:py-8 dark:bg-gradient-to-tr dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="container px-4 py-4 mx-auto">
        <div className="lg:max-w-xl lg:mx-auto mb-6 md:mb-10">
          <h2 className="font-bold text-4xl text-center mb-4 dark:text-white">
            Own your passion.
          </h2>
          <p className="text-sm md:text-base text-center dark:text-white">
            GOALL aspires to provide alternative finance in sport by the entry of the general public as investors. GOALL&apos;s current mission is to assist clubs in recruiting players through their fans. The project also includes a community and gamification component, which involves rallying the public around a game-like activity, networks.
          </p>
        </div>
        <div className="lg:max-w-2xl lg:mx-auto">
          <div className="grid md:gap-8 md:grid-cols-12">
            <div className="md:col-span-5 mb-4 flex items-end">
              <p className="font-bold text-center md:text-left text-lg md:text-2xl lg:text-3xl dark:text-white">
                Experience the excitement of collecting exclusive NFTs. Explore our constantly updated marketplace now.
              </p>
            </div>
            <div className="md:col-span-7 mb-4">
              <img className="w-full md:float-right md:max-w-[300px]" src={ MacBookPro } alt="Post title 2" />
            </div>
          </div>
        </div>
        <div className="lg:max-w-3xl lg:mx-auto mt-6">
          <div className="grid md:gap-8 md:grid-cols-12">
            <div className="md:col-span-6 mb-4 flex items-end">
              <div className="bg-malacite h-full w-full flex items-end p-4 rounded">
                <p className="font-bold text-center mb-2 text-lg md:text-2xl lg:text-5xl text-white">
                  Over 10K stories and counting.
                </p>
              </div>
            </div>
            <div className="md:col-span-6 mb-4">
              <p className="font-bold text-center mb-2 text-lg md:text-2xl lg:text-3xl dark:text-white">
                Expertly curated selection for the ultimate football fan experience.
              </p>
              <img className="w-full" src={ iPhoneMokup } alt="Post title 2" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
