import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthAPI } from '../../app/api/endpoints/Auth';
import ErrorMessage from '../../components/Common/ErrorMessage';
import Loader from '../../components/Common/Loader';
import { handleError } from '../../helpers/Utils';
import { useAuth } from '../../providers/auth/AuthProvider';

export default function GoogleProvider() {
  const navigate = useNavigate();
  const { isConnected, postLogin } = useAuth();
  const [loading , setLoading] = useState<boolean>(false);
  const [error , setError] = useState<string>('');
  const [ search ] = useState<string>(window.location.search);

  useEffect(() => {
    if(search){
      authUserByProvider('google', search);
    }
  }, [ search ]);

  const authUserByProvider = async (provider: string, search: string) => {
    setLoading(true);
    const { result } = await AuthAPI.Login.authProvider(provider, search, (error) => {
      setError(handleError(error));
      setLoading(false);
    });

    if (result) {
      if (result.status) {
        postLogin(result.token);
      } else {
        setError(handleError(result.code));
      }
      setLoading(false);
    }
  };


  useEffect(() => {
    isConnected && navigate('/account');
  }, [ isConnected ]);

  return (
    <section className="h-full lg:h-screen w-full flex pt-16 dark:bg-gradient-to-br dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="px-10 pb-20 pt-10 hidden lg:flex items-end justify-end lg:w-[50%] bg-magenta">
        <div className="w-full max-w-lg">
          <h2 className="mb-8 text-6xl font-bold text-white">
            Unlock the Future of Football.
          </h2>
          <p className="text-lg text-white">
            Experience a new dimension of collecting with unique collectibles featuring your favorite football players. Login now to join the revolution of football.
          </p>
        </div>
      </div>
      <div className="p-4 lg:p-10 w-full lg:w-[50%] flex flex-col items-center justify-center relative">
        {
          loading ? <Loader/> :
            <>
              {
                error && 
                <ErrorMessage
                  title="Error!"
                  content={ error }
                />
              }
            </>
        }
      </div>
    </section>
  );
}
