import 'moment-timezone';

import React from 'react';
import Moment from 'react-moment';

interface ShowDateProps {
  date: Date,
  format?: string,
  fromNow?: boolean
}

function ShowDate({ date, format='DD-MM-YYYY HH:mm:ss', fromNow=false }: ShowDateProps): JSX.Element {
  return (
    fromNow ?
      <Moment fromNow>
        { date }
      </Moment>
      :
      <Moment format={ format }>
        { date }
      </Moment>
  );
}

export default ShowDate;
