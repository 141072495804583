import React, { ReactElement } from 'react';

import About from '../pages/About/About';
import Profile from '../pages/Account/Profile';
import Settings from '../pages/Account/Settings';
import EmailConfirmation from '../pages/Auth/EmailConfirmation';
import ForgetPassword from '../pages/Auth/ForgetPassword';
import GoogleProvider from '../pages/Auth/GoogleProvider';
import Login from '../pages/Auth/Login';
import Register from '../pages/Auth/Register';
import ResetPassword from '../pages/Auth/ResetPassword';
import TwoFactorRecovery from '../pages/Auth/TwoFactorRecovery';
import TwoFactorVerification from '../pages/Auth/TwoFactorVerification';
import Blog from '../pages/Blog/Blog';
import BlogDetails from '../pages/Blog/BlogDetails';
import Home from '../pages/Home/Home';
import MarketPlace from '../pages/MarketPlace/MarketPlace';
import Nft from '../pages/MarketPlace/Nft';
import NotFound from '../pages/NotFound';
import Terms from '../pages/Terms';
import ToolDemo from '../pages/ToolDemo';


export type RouterType = {
  path: string;
  element: ReactElement;
  requiredAuth: boolean;
}
const routes: RouterType[] = [
  {
    path: '/',
    element: <Home/>,
    requiredAuth: false
  },
  {
    path: '/marketplace',
    element: <MarketPlace/>,
    requiredAuth: false
  },
  {
    path: '/nft/:itemId',
    element: <Nft/>,
    requiredAuth: false
  },
  {
    path: '/about',
    element: <About/>,
    requiredAuth: false
  },
  {
    path: '/concept',
    element: <Blog/>,
    requiredAuth: false
  },
  {
    path: '/:postSlug',
    element: <BlogDetails/>,
    requiredAuth: false
  },
  {
    path: '/terms',
    element: <Terms/>,
    requiredAuth: false
  },
  {
    path: '/login',
    element: <Login/>,
    requiredAuth: false
  },
  {
    path: '/verify-2fa',
    element: <TwoFactorVerification/>,
    requiredAuth: false
  },
  {
    path: '/verify-2fa-recovery',
    element: <TwoFactorRecovery/>,
    requiredAuth: false
  },
  {
    path: '/register',
    element: <Register/>,
    requiredAuth: false
  },
  {
    path: '/email-verification',
    element: <EmailConfirmation/>,
    requiredAuth: false
  },
  {
    path: '/forget-password',
    element: <ForgetPassword/>,
    requiredAuth: false
  },
  {
    path: '/reset-password',
    element: <ResetPassword/>,
    requiredAuth: false
  },
  {
    path: '/auth/google',
    element: <GoogleProvider/>,
    requiredAuth: false
  },
  {
    path: '/account',
    element: <Profile/>,
    requiredAuth: true,
  },
  {
    path: '/account/settings',
    element: <Settings />,
    requiredAuth: true,
  },
  {
    path: '/tool-demo',
    element: <ToolDemo />,
    requiredAuth: false,
  },
  {
    path: '/*',
    element: <NotFound/>,
    requiredAuth: false
  }
];

export default routes;
