import React from 'react';

import Coll from '../../assets/img/about/about_collection.png';
import Token from '../../assets/img/vector/token.svg';

export default function SectionTwo() {
  return (
    <section className="bg-white relative -mt-56 md:-mt-48 rounded-t-[50px] pt-3 dark:bg-gradient-to-br dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="container px-4 pb-4 mx-auto">
        <div className="grid lg:gap-8 lg:grid-cols-12 mt-16">
          <div className="lg:col-span-6 flex flex-col justify-center">
            <h2 className="mb-8 text-2xl md:text-5xl font-semibold tracking-tight dark:text-white">
              About Goall
            </h2>
            <p className="text-base font-sf-pro-text font-thin md:max-w-lg mb-8 dark:text-white">
              Welcome to GOALL, the ultimate marketplace for football NFTs. Our brand is dedicated to bringing you the best collection of rare and exclusive NFTs featuring your favorite players and teams.
            </p>
            <h2 className="mb-8 text-2xl md:text-5xl font-semibold tracking-tight dark:text-white">
              Our Mission
            </h2>
            <p className="text-base font-sf-pro-text font-thin md:max-w-lg mb-8 dark:text-white">
              At GOALL, our mission is to provide a safe and secure platform for collectors to buy, sell, and trade football NFTs. We believe in the power of technology to bring fans closer to the game and create a new way to connect with the sport and its stars.
            </p>
          </div>
          <div className="lg:col-span-6 mb-4">
            <div className="relative p-8">
              <img className="w-20 top-0 right-0 md:w-32 absolute md:-top-5 md:-right-5" src={ Token } />
              <img className="rounded-xl" src={ Coll } />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
