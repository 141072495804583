import React from 'react';

interface GoallIconProps {
  className?: string
}
export default function GoallIcon( { className }: GoallIconProps) {
  return (
    <svg className={ className } viewBox="0 0 95 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M73.9373 14.2174L77.0218 0H13.4748L12.6066 4.00986L3.63536 45.6209H3.6283L0.564941 59.8383H9.78325L12.8537 45.6209H31.3962L28.6716 59.8383H85.7108L94.442 14.2174H73.9373ZM78.25 50.8549H47.327L58.9029 45.4591L63.8508 42.8914H63.8579L64.9378 42.3286L65.4319 40.4222L65.446 40.3448H65.4531L66.2577 36.6374H14.7877L20.752 8.98349H65.8413L62.7568 23.2009H83.5438L78.25 50.8549Z"/>
    </svg>
  );
}
