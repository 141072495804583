import React from 'react';

import Club from '../../assets/img/marketplace/club.png';
import Badge from '../../assets/img/vector/badge.svg';
import VecImg from '../../assets/img/vector/vector_mp.svg';

export default function SectionTwo() {
  return (
    <section className="bg-white pt-3 lg:pt-24 dark:bg-gradient-to-tr dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="container px-4 py-4 mx-auto">
        <div className="grid lg:gap-8 lg:grid-cols-12 mt-16">
          <div className="lg:col-span-6 mb-4">
            <div className="relative p-8">
              <img className="w-16 absolute top-0 left-0" src={ Badge } />
              <img className="rounded-xl" src={ Club } />
            </div>
          </div>
          <div className="lg:col-span-6 flex flex-col justify-center">
            <h2 className="mb-8 md:max-w-lg text-3xl sm:text-4xl font-semibold tracking-tight leading-16 md:text-5xl xl:text-[60px] xl:leading-[70px] dark:text-white">
              Discover the Club of the Day ft. : <br />
              <span className="inline-block relative">Phoenix FC <img className="absolute left-0 bottom-0 w-36 md:w-56 lg:w-72" src={ VecImg } /></span>
            </h2>
            <p className="text-base font-sf-pro-text font-thin md:max-w-lg mb-8 dark:text-white">
              Join us in recognizing the accomplishments and promising future of Phoenix FC with GOALL&apos;s Club of the Day. <br />
              Explore unique, one-of-a-kind digital collectibles featuring the club&apos;s greatest players and moments and become a part of their history.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
