import React, { FormEvent, useEffect, useState } from 'react';
import { FaGoogle } from 'react-icons/fa';
import { IoEyeSharp } from 'react-icons/io5';
import { IoEyeOffSharp } from 'react-icons/io5';
import { NavLink, useNavigate } from 'react-router-dom';

import { AuthAPI } from '../../app/api/endpoints/Auth';
import Alert from '../../components/Common/ui/Alert';
import Button from '../../components/Common/ui/Button';
import Divider from '../../components/Common/ui/Divider';
import Input from '../../components/Common/ui/Input';
import { ErrorsType, LoginRequestType } from '../../models/Auth';
import { useAuth } from '../../providers/auth/AuthProvider';
import validateInput from '../../validators/login';

export default function Login() {
  const navigate = useNavigate();
  const { isConnected, loading, checkingConnexion, error, setError, login } = useAuth();
  const [credentials, setCredentials] = useState<LoginRequestType>({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState<ErrorsType>({} as ErrorsType);
  const [loadingProvider, setLoadingProvider] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors({} as ErrorsType);
    setError(undefined);
    if (isValid()) {
      const { email , password } = credentials;
      login(email, password);
    }
  };

  const isValid = () => {
    const { errors, isValid } = validateInput(credentials);
    if (!isValid) {
      setErrors(errors);
    }
    return isValid;
  };

  const onCredentialsChange = (e: FormEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.currentTarget.name]: e.currentTarget.value });
    setErrors({
      ...errors,
      [e.currentTarget.name]: undefined
    });
  };

  const getProviderUrl = async (provider: string) => {
    setLoadingProvider(true);
    const { result } = await AuthAPI.Login.getProviderUrl(provider, () => {
      setLoadingProvider(false);
    });

    if (result) {
      if (result.status) {
        window.location.replace(result.url);
      } else {
        setLoadingProvider(false);
      }
    }
  };

  useEffect(() => {
    isConnected && navigate('/account');
  }, [ isConnected ]);

  return (
    <section className="h-full lg:h-screen w-full flex dark:bg-gradient-to-br dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="px-10 pb-20 pt-10 hidden lg:flex items-end justify-end lg:w-[50%] bg-magenta">
        <div className="w-full max-w-lg">
          <h2 className="mb-8 text-6xl font-bold text-white">
            Unlock the Future of Football.
          </h2>
          <p className="text-lg text-white">
            Experience a new dimension of collecting with unique collectibles featuring your favorite football players. Login now to join the revolution of football.
          </p>
        </div>
      </div>
      <div className="p-4 lg:p-10 pt-20 w-full lg:w-[50%] flex flex-col items-center justify-center overflow-y-auto">
        {
          checkingConnexion ? (
            <div className="h-screen flex flex-col items-center justify-center">
              <svg aria-hidden="true" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-indigo dark:fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span className="text-lg text-gray-900 dark:text-white mt-6">Checking your authentificaton...</span>
            </div>
          ) : (
            !isConnected &&
              <div className="w-full max-w-md">
                <h2 className="text-xl md:text-3xl font-bold lg:text-4xl capitalize mb-6 dark:text-white">Log in</h2>
                { error &&
                  <Alert variant="danger">{ error }</Alert>
                }
                <form onSubmit={ (e) => onSubmit(e) }>
                  <Input
                    type="text"
                    name="email"
                    value={ credentials.email }
                    onChange={ (e) =>  onCredentialsChange(e) }
                    label="Email"
                    placeholder="Enter your Email..."
                    required
                    error={ errors && errors.email }
                  />
                  <div className="relative">
                    <Input
                      type={ showPassword ? 'text' : 'password' }
                      name="password"
                      value={ credentials.password }
                      onChange={ (e) =>  onCredentialsChange(e) }
                      label="Password"
                      placeholder="Enter your Password..."
                      required
                      error={ errors && errors.password }
                    />
                    <div
                      className="cursor-pointer absolute right-4 top-4"
                      onClick={ () => setShowPassword(!showPassword) }
                    >
                      { showPassword ? (
                        <IoEyeOffSharp className="h-6 w-6 font-extralight" />
                      ) : (
                        <IoEyeSharp className="h-6 w-6 font-extralight" />
                      ) }
                    </div>
                  </div>
                  <p className="leading-none dark:text-white">
                    Forget password? <NavLink to="/forget-password" className="text-violet hover:text-purple-800">Tap here</NavLink>
                  </p>
                  <Button
                    type="submit"
                    className="btn btn-primary btn-block mt-6"
                    loading={ loading }
                    spinnerclass="text-white"
                  >
                    Log in
                  </Button>
                </form>
                <Divider text="or continue with"/>
                <Button
                  className="btn btn-light btn-block"
                  loading={ loadingProvider }
                  onClick={ () => getProviderUrl('google') }
                >
                  <FaGoogle className="mr-2"/> Google
                </Button>
                <p className="text-center mt-6 dark:text-white">Don’t have an account yet? <NavLink to="/register" className="text-malacite">Tap here</NavLink></p>
              </div>
          )
        }
      </div>
    </section>
  );
}

