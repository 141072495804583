import React, { FormEvent, useEffect, useState } from 'react';
// @ts-ignore
import { useCountries } from 'use-react-countries';

import { UserAPI } from '../../app/api/endpoints/User';
import { format422Error, handleError } from '../../helpers/Utils';
import { User } from '../../models/User';
import validateInput from '../../validators/profile';
import { Alert, Button, Input } from '../Common/ui';
import SelectInput, { Option } from '../Common/ui/Select';

interface EditProfileProps {
  profile: User | undefined;
  updateProfile: (user: User) => void
}
type UserProfile = Partial<User>;

export default function EditProfile({ profile, updateProfile }: EditProfileProps) {
  const { countries } = useCountries();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<UserProfile>({} as UserProfile);
  const [inputs, setInputs] = useState<UserProfile>({
    name: '',
    username: '',
    city: '',
    zip_code: '',
    address: '',
    country: profile?.country,
  });
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);

  useEffect(() => {
    if(profile) {
      setInputs({
        name: profile.name,
        username: profile.username,
        city: profile.city,
        zip_code: profile.zip_code,
        address: profile.address,
        country: profile.country,
      });
    }
  }, [ profile ]);

  const onInputsChange = (e: FormEvent<HTMLInputElement>) => {
    setInputs({ ...inputs, [e.currentTarget.name]: e.currentTarget.value });
    setErrors({
      ...errors,
      [e.currentTarget.name]: undefined
    });
  };

  const onSlectChange = (option: Option | null) => {
    setInputs({ ...inputs, country: option?.value as string });
    setErrors({
      ...errors,
      country: ''
    });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors({} as UserProfile);
    setError(undefined);
    setSuccess(undefined);
    if (isValid()) {
      setLoading(true);
      const { result } = await UserAPI.Profile.updateProfile(inputs, (error) => {
        setLoading(false);
        if (error && error.response && error.response?.status == 422) {
          // @ts-ignore
          setErrors(format422Error(error?.response?.data?.errors));
        } else {
          setError(handleError(error));
        }
      });
  
      if (result) {
        if (result.status) {
          updateProfile(result.profile);
          setSuccess('Profile updated Successfully');
        } else {
          setError(handleError(result.code));
        }
        setLoading(false);
      }
    }
  };

  const isValid = () => {
    const { errors, isValid } = validateInput(inputs);
    if (!isValid) {
      setErrors(errors);
    }
    return isValid;
  };

  return (
    <div className="mt-4">
      { success &&
        <Alert variant="success">{ success }</Alert>
      }
      { error &&
        <Alert variant="danger">{ error }</Alert>
      }
      <form onSubmit={ (e) => onSubmit(e) }>
        <Input
          type="text"
          name="name"
          label="Full Name"
          placeholder="Enter your full name..."
          required
          value={ inputs.name }
          onChange={ (e) =>  onInputsChange(e) }
          error={ errors && errors.name }
        />
        <Input
          type="text"
          name="username"
          label="Username"
          placeholder="Enter your username..."
          required
          value={ inputs.username }
          onChange={ (e) =>  onInputsChange(e) }
          error={ errors && errors.username }
        />
        <div className="grid md:gap-6 md:grid-cols-12">
          <div className="md:col-span-7">
            <Input
              type="text"
              name="city"
              label="City"
              placeholder="Enter your city name..."
              required
              value={ inputs.city }
              onChange={ (e) =>  onInputsChange(e) }
              error={ errors && errors.city }
            />
          </div>
          <div className="md:col-span-5">
            <Input
              type="text"
              name="zip_code"
              label="Postal code"
              placeholder="Enter your Postal code..."
              required
              value={ inputs.zip_code }
              onChange={ (e) =>  onInputsChange(e) }
              error={ errors && errors.zip_code }
            />
          
          </div>
        </div>
        <Input
          type="text"
          name="address"
          label="Street address"
          placeholder="Enter your street adress..."
          required
          value={ inputs.address }
          onChange={ (e) =>  onInputsChange(e) }
          error={ errors && errors.address }
        />
        <SelectInput
          name="country"
          label="Country"
          required
          defaultValue={{ label: inputs.country, value: inputs.country }}
          options={ countries.map(
            ({ name }: { name: string }) => ({ value: name, label: name })) }
          onChange={ (e: Option) => onSlectChange(e) }
          error={ errors && errors.country }
        />
        <Button
          type="submit"
          className="btn btn-light btn-block mt-4"
          loading={ loading }
          spinnerclass="text-white"
        >
        save changes
        </Button>
      </form>
    </div>
  );
}
