import React from 'react';
import { IoHeart } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';

import LogoIcon from '../../assets/img/logo_icon.svg';

interface PlayerCardProps {
  player: {
    id: number,
    name: string,
    username: string,
    price: number,
    supply: string,
    clubIcon: string,
    image: string,
    raiting: number,
  }
} 
export default function PlayerCard({ player } : PlayerCardProps) {
  return (
    <div className="flex flex-col max-w-[360px] mx-auto">
      <div>
        <NavLink to="/nft">
          <img className="rounded-t-xl" src={ require(`../../assets/img/clubs/players/${player.image}.svg`) } alt={ player.name } />
        </NavLink>
      </div>
      <div className="flex flex-col rounded-b-xl bg-gray-200 p-3">
        <h4 className="text-2xl sm:text-xl md:text-2xl">
          <NavLink to="/nft">
            { player.name }
          </NavLink>
        </h4>
        <div className="flex gap-2 items-center mb-2">
          <img className="w-6 h-6" src={ require(`../../assets/img/clubs/icons/${player.clubIcon}.png`) } alt={ player.name } />
          <p className="text-xl text-grey">{ player.username }</p>
        </div>
        <div className="flex gap-2 items-center justify-between">
          <div>
            <h4 className="text-2xl sm:text-xl md:text-2xl text-bold leading-none mb-0">
              <img className="w-8 h-10 inline-block" src={ LogoIcon } alt="Goall" /> ${ player.price }/NFT
            </h4>
            <p className="text-sm text-grey">supply: { player.supply }</p>
          </div>
          <p className="text-sm inline-flex items-center"><IoHeart className="mr-1"/> { player.raiting }</p>
        </div>
      </div>
    </div>
  );
};
