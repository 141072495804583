import React,
{
  createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState 
} from 'react';


interface ThemeProviderProps {
  children?: ReactNode;
}

interface ThemeContextProps {
  theme: string,
  setTheme: Dispatch<SetStateAction<string>>,
}

export const ThemeContext = createContext<ThemeContextProps>({} as ThemeContextProps);

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState<string>('light');

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove('dark');
    if(theme === 'dark') {
      root.classList.add('dark');
    }
  }, [ theme ]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme
      }}>
      { children }
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
