import React from 'react';

import BgImg from '../../assets/img/bg/live_auction.svg';

export default function LiveAuction() {
  return (
    <section
      className="bg-white h-[90px] xs:h-[120px] sm:h-[150px] md:h-[200px] lg:h-[260px] bg-cover dark:bg-indigo2"
      style={{ backgroundImage: `url(${BgImg})` }}
    />
  );
};
