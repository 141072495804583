import React, { useState } from 'react';
import { IoLogOutOutline } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';

import UserCover from '../../assets/img/profile/user_cover.png';
import { getInitials } from '../../helpers/Utils';
import { User } from '../../models/User';
import GoallIcon from '../Common/GoallIcon';
import { Button } from '../Common/ui';
import UpdateAvatar from './UpdateAvatar';
import UpdateCoverImage from './UpdateCoverImage';

interface ProfileHeaderProps {
  profile: User | undefined;
  logout: () => void;
  onMenuChange: (arg: ProfileMenu) => void
}
export enum ProfileMenu {
  Gallery = 'Gallery',
  PaymentHistory = 'PaymentHistory',
}

export default function ProfileHeader({ profile, logout, onMenuChange }: ProfileHeaderProps) {
  const [menu, setMenu] = useState<ProfileMenu>(ProfileMenu.Gallery);
  
  return (
    <>
      <div
        className="min-h-[100px] md:min-h-[200px] rounded-lg bg-cover relative overflow-hidden"
        style={{ backgroundImage: `url(${profile?.cover_image ? profile.cover_image : UserCover})` }}
      >
        <UpdateCoverImage/>
      </div>
      <div className="flex flex-col -mt-9 ml-9 md:-mt-14 md:ml-14 relative">
        <div className="w-12 h-12 md:w-20 md:h-20 rounded-full text-xl flex justify-center items-center bg-gray-700 relative">
          {
            profile?.avatar ?
              <img className="rounded-full" src={ profile?.avatar } /> :
              <span className="text-white">{ getInitials(profile?.name) }</span>
          }
          <UpdateAvatar/>
        </div>
        <h3 className="text-xl md:text-2xl font-bold mt-1 dark:text-white">@{ profile?.username }</h3>
      </div>
      <div className="sm:ml-9 sm:mr-9 md:mr-14 md:ml-14 mt-6">
        <div className="grid md:gap-8 md:grid-cols-12">
          <div className="md:col-span-8 mb-4">
            <p className="text-sm dark:text-white">
                Football NFT collector sharing my journey and passion for the game. <br /> Artist / Creative Director ✹ #NFT minting 
            </p>
            <div className="flex flex-col gap-2 xs:flex-row xs:gap-4 mt-6">
              {
                menu === ProfileMenu.PaymentHistory &&
                <Button
                  className="btn btn-secondary"
                  onClick={ () => {
                    setMenu(ProfileMenu.Gallery);
                    onMenuChange(ProfileMenu.Gallery);
                  } }
                >
                    Gallery
                </Button>
              }
              {
                menu === ProfileMenu.Gallery &&
                <Button
                  className="btn btn-secondary"
                  onClick={ () => {
                    setMenu(ProfileMenu.PaymentHistory);
                    onMenuChange(ProfileMenu.PaymentHistory);
                  } }
                >
                  Payment History
                </Button>
              }
              <NavLink className="btn btn-primary" to="/account/settings">
                  Account Settings
              </NavLink>
              <Button
                className="btn btn-secondary"
                onClick={ logout }
              >
                <IoLogOutOutline className="w-6 h-6 mr-2"/>  Logout
              </Button>
            </div>
          </div>
          <div className="md:col-span-4 mb-4">
            <div className="p-5 rounded-lg border border-dark2 dark:border-grey2 md:mt-4">
              <div className="flex justify-between items-center text-sm mb-4 dark:text-white">
                <span>Following</span>
                <span className="font-bold">24</span>
              </div>
              <div className="flex justify-between items-center text-sm dark:text-white">
                <span>Owned</span>
                <span className="font-bold"><GoallIcon className="w-5 mr-2 -mt-1 inline-block fill-black dark:fill-white"/> 13k</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
