import React, { useState } from 'react';

import { useAuth } from '../../providers/auth/AuthProvider';
import { Modal, ModalBody } from '../Common/Modal';
import { Button } from '../Common/ui';
import DisableTwoFa from './DisableTwoFa';
import TwoFaSteps from './TwoFaSteps';


export default function TwoFactorAuth() {
  const { twoFactorStatus, updateTwoFactorStatus } = useAuth();
  const [showActivateModal, setShowActivateModal] = useState<boolean>(false);
  const [showDisableModal, setShowDisableModal] = useState<boolean>(false);

  return (
    <>
      {
        twoFactorStatus ? (
          <>
            <p className="dark:text-white">Two Factor Authentication activated</p>
            <Button
              className="btn btn-danger btn-block mt-4"
              spinnerclass="text-white"
              onClick={ () => setShowDisableModal(true) }
            >
              Disable Two Factor Authentication
            </Button>
          </>
        ) : (
          <>
            <p className="dark:text-white">Not activated</p>
            <Button
              className="btn btn-primary btn-block mt-4"
              spinnerclass="text-white"
              onClick={ () => setShowActivateModal(true) }
            >
              Enable Two Factor Authentication
            </Button>
          </>
        )
      }
      <Modal
        showModal={ showActivateModal }
        setShowModal={ setShowActivateModal }
      >
        <ModalBody>
          <TwoFaSteps closeModal={ () => {
            setShowActivateModal(false);
            updateTwoFactorStatus(true);
          } }/>
        </ModalBody>
      </Modal>
      <Modal
        showModal={ showDisableModal }
        setShowModal={ setShowDisableModal }
      >
        <ModalBody>
          <DisableTwoFa closeDisableModal={ () => {
            setShowDisableModal(false);
            updateTwoFactorStatus(false);
          } }/>
        </ModalBody>
      </Modal>
    </>
  );
}
