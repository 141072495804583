import { RequestWrapper } from '../../../helpers/RequestWrapper';
import API from '..';

const MARKETPLACE_ENDPOINT = 'marketplace';

class MarketPlaceAPI {
  static getAllCollections = (errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${MARKETPLACE_ENDPOINT}/collections`)).data, errorHandler
    );
  };

  static getCollection = (collectionId: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${MARKETPLACE_ENDPOINT}/collection/${collectionId}/show`)).data, errorHandler
    );
  };

  static getAllItems = (collectionId: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${MARKETPLACE_ENDPOINT}/collection/${collectionId}/items`)).data, errorHandler
    );
  };

  static getItem = (collectionId: string | undefined, itemId: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${MARKETPLACE_ENDPOINT}/collection/${collectionId}/item/${itemId}/get`)).data, errorHandler
    );
  };

  static redeemItemMainnet = (data: any, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.post(`${MARKETPLACE_ENDPOINT}/collection/redeem/mainnet`, data)).data, errorHandler
    );
  };

  static redeemItemTestnet = (data: any, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.post(`${MARKETPLACE_ENDPOINT}/collection/redeem/testnet`, data)).data, errorHandler
    );
  };
}

export { MARKETPLACE_ENDPOINT, MarketPlaceAPI };
