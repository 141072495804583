import React, { useEffect, useState } from 'react';
import { IoFilterOutline , IoGridOutline, IoReloadOutline } from 'react-icons/io5';

import { MarketPlaceAPI } from '../../app/api/endpoints/Marketplace';
import { handleError } from '../../helpers/Utils';
import { Collection, LocalCollection } from '../../models/Collection';
import CollectionCard from '../Cards/CollectionCard';
import { Button } from '../Common/ui';
import CardPlaceholder from './Skeleton/CardPlaceholder';

export default function CollectionComp() {
  const [loading, setLoading] = useState<boolean>(false);
  const [collections, setCollections] = useState<LocalCollection[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    setLoading(true);
    const { result } = await MarketPlaceAPI.getAllCollections((error) => {
      setLoading(false);
      handleError(error);
    });

    if (result) {
      setLoading(false);
      if (result.status) {
        setCollections(result.collections.filter((res: Collection) => res.visibility === true));
      }
    }
  };

  return (
    <>
      <div className="grid grid-cols-4 gap-2 md:grid-cols-12 md:gap-6 mt-10">
        <div className="col-span-1 md:col-span-2">
          <Button className="btn btn-light btn-block mr-0"> <IoFilterOutline className="mr-2"/> Filter</Button>
        </div>
        <div className="col-span-3 md:col-span-6 lg:col-span-7">
          <div className="relative w-full">
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-700" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
            </div>
            <input type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:outline-none focus:border-gray-700 block w-full pr-10 p-[13px] dark:bg-grey2 dark:border-gray-600 dark:placeholder-gray-700 dark:text-gray-900" placeholder="Search Mockups, Logos, Design Templates..." required />
          </div>
        </div>
        <div className="col-span-3 md:col-span-3 lg:col-span-2">
          <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:outline-none focus:border-blue-500 block w-full p-3.5 dark:bg-grey2 dark:border-gray-600 dark:placeholder-gray-700 dark:text-gray-900">
            <option>Price: low to hight</option>
          </select>
        </div>
        <div className="col-span-1 md:col-span-1 text-right">
          <Button className="btn btn-light py-4 mr-0"> <IoGridOutline/></Button>
        </div>
      </div>
      <div className="grid sm:grid-cols-4 md:grid-cols-12 gap-4 mt-10">
        {
          loading ? (
            <CardPlaceholder repeat={ 4 } classes="sm:col-span-2 md:col-span-4 lg:col-span-3 mb-4"/>
          ) : (
            collections.map(collection => (
              <div key={ collection.collection_id } className="sm:col-span-2 md:col-span-4 lg:col-span-3 mb-4">
                <CollectionCard
                  collection={ collection }
                />
              </div>
            ))
          )
        }
      </div>
      <div className="lg:col-span-12 text-center">
        <a href="#" className="text-base underline dark:text-white"><IoReloadOutline className="inline-block text-xl"/></a>
      </div>
    </>
  );
};
