import { toast } from 'react-toastify';
import swal from 'sweetalert2';

import i18n from '../i18n';

export const confirmAlert = () => {
  const swalWithtw = swal.mixin({
    customClass: {
      title: '!text-2xl',
      confirmButton: 'btn btn-primary mr-2',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  });
  return new Promise((resolve, reject) => {
    swalWithtw.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, do it!'
    }).then((result:any) => {
      if (result.isConfirmed) {
        resolve(true);
      } else {
        reject(false);
      }
    });
  });
};

export const errorAlert = (content: string) => {
  return toast.error(content);
};

export const excerptString = (string: string, maxLength: number , escapeHtml = true) => {
  let plainText;
  if (escapeHtml) {
    // Remove HTML tags from the string
    const regex = /(<([^>]+)>)/gi;
    plainText = string.replace(regex, '');
  } else {
    plainText = string;
  }

  // Check if the string exceeds the maximum length
  if (plainText.length <= maxLength) {
    return string; // Return the original string if it's within the limit
  } else {
    // Excerpt the string and escape HTML entities
    const excerpt = plainText.substring(0, maxLength);
    const lastSpaceIndex = excerpt.lastIndexOf(' ');
    const excerptWithoutLastWord = excerpt.substring(0, lastSpaceIndex);
    return excerptWithoutLastWord + '...'; // Return the excerpted string with ellipsis
  }
};


export const format422Error = (errors: any) => {
  return errors.map((err: any) => {
    return {
      [err.field]: err.message
    };
  }).reduce(function(result: any, item: any) {
    const key = Object.keys(item)[0];
    result[key] = item[key];
    return result;
  }, {});
};



export const formatNumber = (inputNumber: string | number) => {
  let formetedNumber=(Number(inputNumber)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  const splitArray=formetedNumber.split('.');
  if(splitArray.length>1){
    formetedNumber=splitArray[0];
  }
  return(formetedNumber.replaceAll(',', '.'));
};


/**
 * Return the first char of given full name, ex 'MN'
 * @param string 
 * @returns 
 */
export const getInitials = (string: string | undefined) => {
  if(string) {
    const names = string.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
  return '??';
};

export const handleError = (error: any) => {
  if (typeof error === 'object' && error.response && error.response.status) {
    switch (error.response.status) {
    case 401:
      return i18n.t('401');
      break;
    case 403:
      return error.response.data.message;
      break;
    case 404:
      return i18n.t('404');
      break;
    case 405:
      return i18n.t('405');
      break;
    case 500:
      return i18n.t('500');
      break;
    default:
      return i18n.t('default');
      break;
    }
  } else {
    switch (error) {
    case -1002:
      return i18n.t('1002');
      break;
    case -1101:
      return i18n.t('1101');
      break;
    case -1102:
      return i18n.t('1102');
      break;
    case -1103:
      return i18n.t('1103');
      break;
    case -1104:
      return i18n.t('1104');
      break;
    case -1105:
      return i18n.t('1105');
      break;
    case -1106:
      return i18n.t('1106');
      break;
    case -1107:
      return i18n.t('1107');
      break;
    case -1108:
      return i18n.t('1108');
      break;
    case -1201:
      return i18n.t('1201');
      break;
    case -1202:
      return i18n.t('1202');
      break;
    case -1203:
      return i18n.t('1203');
      break;
    case -1204:
      return i18n.t('1204');
      break;
    case -1205:
      return i18n.t('1205');
      break;
    case -1206:
      return i18n.t('1206');
      break;
    default:
      return i18n.t('default');
      break;
    }
  }
};

export const successAlert = (content: string) => {
  return toast.success(content);
};

export const wrongAlert = () => {
  return toast.error('Something went wrong please try again.');
};
