// @ts-ignore
import _ from 'lodash';
// @ts-ignore
import Validator from 'validator';

import { User } from '../models/User';
import { REQUIRED } from './messages';


export default function validateInput(data: Partial<User>) {
  const errors = {
    phone: '',
  };

  if (!data.phone || Validator.isEmpty(data.phone)) {
    errors.phone = REQUIRED;
  }

  return {
    errors,
    isValid: _.values(errors).every(_.isEmpty)
  };
}
