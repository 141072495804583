import React from 'react';

import Collection from '../Common/Collection';


export default function SimilarPacks() {
  return (
    <section className="bg-white py-3 md:py-8 dark:bg-gradient-to-t dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="container px-4 py-4 mx-auto">
        <h2 className="text-xl md:text-2xl font-bold lg:text-4xl dark:text-white">
          Similar packs
        </h2>
        <Collection/>
      </div>
    </section>
  );
}
