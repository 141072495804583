import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AuthAPI } from '../../app/api/endpoints/Auth';
import ErrorMessage from '../../components/Common/ErrorMessage';
import Loader from '../../components/Common/Loader';
import Success from '../../components/Common/SuccessMessage';
import { handleError } from '../../helpers/Utils';
import { useAuth } from '../../providers/auth/AuthProvider';

export default function EmailConfirmation() {
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate();
  const { isConnected } = useAuth();
  const [token , setToken] = useState<string | null>(null);
  const [loading , setLoading] = useState<boolean>(false);
  const [success , setSuccess] = useState<boolean>(false);
  const [error , setError] = useState<string>('');


  useEffect(() => {
    setToken(searchParams.get('token'));
  }, [ searchParams ]);

  useEffect(() => {
    if(token){
      emailVerification(token);
    }
  }, [ token ]);

  const emailVerification = async (token: string | null) => {
    setLoading(true);
    const { result } = await AuthAPI.EmailVerification.post(token, (error) => {
      setError(handleError(error));
      setLoading(false);
    });

    if (result) {
      if (result.status) {
        setSuccess(true);
      } else {
        setError(handleError(result.code));
      }
      setLoading(false);
    }
  };


  useEffect(() => {
    isConnected && navigate('/account');
  }, [ isConnected ]);

  return (
    <section className="h-full lg:h-screen w-full flex pt-16 dark:bg-gradient-to-br dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="px-10 pb-20 pt-10 hidden lg:flex items-end justify-end lg:w-[50%] bg-magenta">
        <div className="w-full max-w-lg">
          <h2 className="mb-8 text-6xl font-bold text-white">
            Unlock the Future of Football.
          </h2>
          <p className="text-lg text-white">
            Experience a new dimension of collecting with unique collectibles featuring your favorite football players. Login now to join the revolution of football.
          </p>
        </div>
      </div>
      <div className="p-4 lg:p-10 w-full lg:w-[50%] flex flex-col items-center justify-center relative">
        {
          loading ? <Loader/> :
            <>
              {
                success && 
                <Success
                  title="Verification Done"
                  content="Your email has been succsfully verified."
                  linkText="Login"
                  linkTo="/login"
                />
              }
              {
                error && 
                <ErrorMessage
                  title="Error!"
                  content={ error }
                />
              }
            </>
        }
      </div>
    </section>
  );
}
