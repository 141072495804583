import React from 'react';

import Bg from '../../assets/img/marketplace/hero.png';
import VecImg from '../../assets/img/vector/vector_2.svg';

export default function Hero() {
  return (
    <section className="bg-white lg:h-screen lg:mt-16 py-4 lg:py-24 relative bg-cover dark:bg-gradient-to-tl dark:from-indigo2 dark:via-indigo2 dark:to-indigo3"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="container px-4 mx-auto">
        <div className="grid lg:gap-8 xl:gap-0 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-6 pt-14">
            <h1 className="max-w-2xl mb-4 font-extrabold tracking-tight text-[44px] leading-[60px] xl:text-[60px] xl:leading-[80px] text-white">
              Unlock <span className="inline-block relative">the full <img className="absolute left-0 bottom-0 w-44 lg:w-full" src={ VecImg } /></span> <br /> potential of your collection.
            </h1>
            <p className="max-w-md mb-6 font-sf-pro-text font-light text-white lg:mb-8 text-base md:text-lg lg:text-x">
              Browse and buy unique, one-of-a-kind digital collectibles and join the revolution of NFTs in football collecting.
            </p>
          </div>               
        </div>
      </div>
    </section>
  );
};
