import React from 'react';

import GoallIcon from '../Common/GoallIcon';

export default function ActivityCard() {
  return (
    <div className="p-4 rounded-lg bg-dark2">
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <img src="https://i.pravatar.cc/48" alt="User 1" className="w-12 h-12 rounded-full" />
          <div className="text-gray-200 font-thin text-sm">
            <p className="mb-1">@marterium</p>
            <p>21 h ago</p>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex items-start gap-2">
            <GoallIcon className="h-4 mt-1.5 fill-white"/>
            <span className="text-xl font-bold text-white">$162.2/NFT</span>
          </div>
          <div className="text-gray-400 font-thin text-sm text-center">
            ≈ $500
          </div>
        </div>
      </div>
    </div>
  );
};
