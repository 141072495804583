import React, { ReactElement } from 'react';

interface AccordionProps {
  children?: ReactElement[];
}
export function Accordion( { children }: AccordionProps) {

  return (
    <div className="mb-6">
      { children }
    </div>
  );
}

interface AccordionBodyProps {
  children?: ReactElement[] | ReactElement;
  open: boolean
}
export function AccordionBody( { open, children }: AccordionBodyProps) {

  return (
    <div className={ `pt-4 transition-all delay-500 ease-in-out ${open ? 'block' : 'hidden'}` }>
      { children }
    </div>
  );
}

interface AccordionHeaderProps {
  onPress: () => void;
  children?: ReactElement | string;
  open: boolean
}
export function AccordionHeader( { onPress, open, children }: AccordionHeaderProps) {

  return (
    <button onClick={ onPress } type="button" className="flex items-center justify-between w-full font-medium text-left text-dark2 border-b border-gray-200 pb-3 dark:text-white">
      <span className="text-base font-bold">{ children }</span>
      <svg className={ `w-6 h-6 shrink-0 transition-all delay-100 ease-in-out ${open && 'rotate-180'}` } fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
    </button>
  );
}
