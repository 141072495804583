import React, { ReactElement } from 'react';

interface ModalProps {
  children?: ReactElement[] | ReactElement;
  showModal: boolean;
  setShowModal: (arg: boolean) => void;
  closable?: boolean;
  confirmable?: boolean;
  clickOutside?: boolean;
}
export function Modal( { children, showModal, setShowModal, closable=true, clickOutside }: ModalProps) {

  return (
    showModal ? (
      <>
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[1001] outline-none focus:outline-none"
          onClick={ () => clickOutside && setShowModal(false) }
        >
          <div className="relative w-auto my-6 mx-auto max-w-xl sm:min-w-[400px]">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full max-h-screen bg-white outline-none focus:outline-none dark:bg-indigo2">
              { children }
            </div>
            { closable &&
              <button
                className="ml-auto bg-transparent border-0outline-none focus:outline-none absolute top-0 right-1"
                onClick={ () => closable && setShowModal(false) }
              >
                <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none dark:text-white">
                  ×
                </span>
              </button>
            }
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-[1000] bg-black dark:bg-white"></div>
      </>
    ) : null
    
  );
}

interface ModalBodyProps {
  children?: ReactElement[] | ReactElement;
}
export function ModalBody( { children }: ModalBodyProps) {

  return (
    <div className="relative px-6 pt-6 pb-3 flex-auto overflow-auto">
      { children }
    </div>
  );
}

interface ModalFooterProps {
  children?: ReactElement[] | ReactElement;
}
export function ModalFooter( { children }: ModalFooterProps) {

  return (
    <>
      <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
        { children }
      </div>
    </>
  );
}

interface ModalHeaderProps {
  children?: ReactElement | string;
}
export function ModalHeader( { children }: ModalHeaderProps) {

  return (
    <>
      { /* header*/ }
      <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
        { children }
      </div></>
  );
}
