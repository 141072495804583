import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false,
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'],
      lookupLocalStorage: 'i18nlanguage',
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

const lang = localStorage.getItem('i18nlanguage');
let browserLang: any = [];
if(typeof navigator !== 'undefined') {
  browserLang = ((navigator.languages && navigator.languages[0]) || navigator.language).toLowerCase().split('-');
}

if(lang && ['en', 'fr'].includes(lang)){
  i18n.changeLanguage(lang);
} else if (browserLang.length>0 && !lang  && ['en', 'fr'].includes(browserLang[0])){
  i18n.changeLanguage(browserLang[0]);
} else {
  i18n.changeLanguage('en');
}

export default i18n;
