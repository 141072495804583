import React from 'react';
import { FaInstagramSquare } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaTwitterSquare } from 'react-icons/fa';
import { FaGithubSquare } from 'react-icons/fa';
import { FaGooglePlusSquare } from 'react-icons/fa';
import { FaPinterestSquare } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

import Bg from '../../assets/img/bg/footer_bg.png';
import { useTheme } from '../../providers/ThemeProvider';

export default function Footer() {
  const { theme, setTheme } = useTheme();
  
  return (
    <footer
      className="border-t border-t-violet pt-6 pb-4 bg-cover bg-left-bottom bg-no-repeat dark:bg-black"
      style={{ backgroundImage: theme === 'light' ? `url(${Bg})` : 'none' }}
    >
      <div className="container px-4 py-4">
        <div className="grid md:gap-8 md:grid-cols-12">
          <div className="md:col-span-6 mb-4">
            <div className="md:max-w-[70%]">
              <h3 className="lext-xl font-bold md:text-4xl md:leading-[54px] mb-2 dark:text-white">
                Our platform is trusted by millions, & offers a variety of financial products.
              </h3>
              <a className="dark:text-white" href="mailto:info@goall.io">info@goall.io</a>
              <div className="mt-4">
                <p className="text-base font-bold mb-2 dark:text-white">Subscribe and get news</p>
                <div className="relative">
                  <input type="text" id="floating_outlined" className="block px-2.5 pb-2.5 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-dark2 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                  <label htmlFor="floating_outlined" className="absolute text-base text-gray-500 bg-[#F4FDFB] md:bg-[#e3f8f4] dark:bg-black dark:text-white duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                    Email 
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="md:col-span-6 flex flex-col items-center justify-end">
            <ul className="list-none flex justify-center mt-6 md:mt-0 mb-6 md:mb-24">
              <li className="relative before:content-['/'] px-2 sm:px-4 before:absolute before:right-0">
                <NavLink className="text-base dark:text-white" to="/">Home</NavLink>
              </li>
              <li className="relative before:content-['/'] px-2 sm:px-4 before:absolute before:right-0">
                <NavLink className="text-base dark:text-white" to="/marketplace">Marketplace</NavLink>
              </li>
              <li className="relative before:content-['/'] px-2 sm:px-4 before:absolute before:right-0">
                <NavLink className="text-base dark:text-white" to="/about">About Us </NavLink>
              </li>
              <li className="px-2 sm:px-4">
                <NavLink className="text-base dark:text-white" to="/concept">Concept</NavLink>
              </li>
            </ul>
            <ul className="list-none flex gap-3 md:gap-6 mb-4 md:mb-2">
              <li className="transform transition duration-500 hover:scale-125">
                <FaInstagramSquare className="w-8 h-8 dark:fill-white"/>
              </li>
              <li className="transform transition duration-500 hover:scale-125">
                <FaLinkedin className="w-8 h-8 dark:fill-white"/>
              </li>
              <li className="transform transition duration-500 hover:scale-125">
                <FaFacebookSquare className="w-8 h-8 dark:fill-white"/>
              </li>
              <li className="transform transition duration-500 hover:scale-125">
                <FaTwitterSquare className="w-8 h-8 dark:fill-white"/>
              </li>
              <li className="transform transition duration-500 hover:scale-125">
                <FaGithubSquare className="w-8 h-8 dark:fill-white"/>
              </li>
              <li className="transform transition duration-500 hover:scale-125">
                <FaGooglePlusSquare className="w-8 h-8 dark:fill-white"/>
              </li>
              <li className="transform transition duration-500 hover:scale-125">
                <FaPinterestSquare className="w-8 h-8 dark:fill-white"/>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-between mt-8">
          <ul className="list-none flex gap-2 xs:gap-4 md:gap-8 mb-4 md:mb-0">
            <li>
              <a className="text-sm dark:text-white" href="">Privacy policy</a>
            </li>
            <li>
              <NavLink className="text-sm dark:text-white" to="/terms">Term of service</NavLink>
            </li>
            <li>
              <span
                className="text-sm dark:text-white cursor-pointer"
                onClick={ () => setTheme(prevState => prevState === 'light' ? 'dark' : 'light' ) }
              >
                Switch to <span className="capitalize">{ theme === 'light' ? 'dark' : 'light' }</span> Mode
              </span>
            </li>
          </ul>
          <p className="text-sm font-sf-pro-text dark:text-white">
            © 2023 GOALL
          </p>
        </div>
      </div>
    </footer> 
  );
};
