import React, { useState } from 'react';

import { AuthAPI } from '../../app/api/endpoints/Auth';
import { Alert, Button, Input } from '../../components/Common/ui/';
import { handleError } from '../../helpers/Utils';
import { emailRequestType } from '../../models/Auth';
import validateInput from '../../validators/email';

export default function ForgetPassword() {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState<emailRequestType>({} as emailRequestType);
  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<string | undefined>();

  const onSubmit = async () => {
    setErrors({} as emailRequestType);
    setError(undefined);
    setSuccess(undefined);
    if (isValid()) {
      setLoading(true);
      const { result } = await AuthAPI.ResetPassword.sendLink(email, (error) => {
        setError(handleError(error));
        setLoading(false);
      });
  
      if (result) {
        if (result.status) {
          setSuccess('Reset password link has been sent successfully.');
        } else {
          setError(handleError(result.code));
        }
        setLoading(false);
      }
    }
  };

  const isValid = () => {
    const { errors, isValid } = validateInput(email);
    if (!isValid) {
      setErrors(errors);
    }
    return isValid;
  };

  return (
    <section className="h-full lg:h-screen w-full flex pt-16 dark:bg-gradient-to-br dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="px-10 pb-20 pt-10 hidden lg:flex items-end justify-end lg:w-[50%] bg-magenta">
        <div className="w-full max-w-lg">
          <h2 className="mb-8 text-6xl font-bold text-white">
            Unlock the Future of Football.
          </h2>
          <p className="text-lg text-white">
            Experience a new dimension of collecting with unique collectibles featuring your favorite football players. Login now to join the revolution of football.
          </p>
        </div>
      </div>
      <div className="p-4 lg:p-10 w-full lg:w-[50%] flex flex-col items-center justify-center">
        <div className="w-full max-w-md">
          <h2 className="text-xl md:text-3xl font-bold lg:text-4xl capitalize mb-6 dark:text-white">Reset password link</h2>
          { success &&
            <Alert variant="success">{ success }</Alert>
          }
          { error &&
            <Alert variant="danger">{ error }</Alert>
          }
          <Input
            type="text"
            name="email"
            label="Email"
            placeholder="Enter your Email..."
            value={ email }
            onChange={ (e) =>  setEmail(e.target.value.toLowerCase()) }
            error={ errors && errors.email }
            required
          />
          <Button
            type="submit"
            className="btn btn-primary btn-block mt-4"
            loading={ loading }
            spinnerclass="text-white"
            onClick={ onSubmit }
          >
            Send Link
          </Button>
        </div>
      </div>
    </section>
  );
}
