import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { BlogAPI } from '../../app/api/endpoints/Blog';
import Bg from '../../assets/img/bg/green_grad.png';
import BlogCard from '../../components/Cards/BlogCard';
import Empty from '../../components/Common/Empty';
import ShowDate from '../../components/Common/ShowDate';
import CardPlaceholder from '../../components/Common/Skeleton/CardPlaceholder';
import ImagePlaceholder from '../../components/Common/Skeleton/ImagePlaceholder';
import { excerptString, handleError } from '../../helpers/Utils';
import { Post } from '../../models/Blog';
import { useTheme } from '../../providers/ThemeProvider';

export default function Blog() {
  const { theme } = useTheme();
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState<Post[]>([]);
  const [firstPost, setFirstPost] = useState<Post>();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 1,
    total: 1
  });

  useEffect(() => {
    getPosts({ page: pagination?.current });
  }, []);

  const getPosts = async (params = {}) => {
    setLoading(true);
    const { result } = await BlogAPI.getAllPosts(`?${qs.stringify(params, { skipNulls: true })}`, (error) => {
      setLoading(false);
      handleError(error);
    });

    if (result) {
      setLoading(false);
      if (result.status) {
        const posts = result.posts.data;
        setFirstPost(posts.shift());
        setPosts(posts);
        setPagination( {
          current: result.posts.meta.current_page,
          pageSize: result.posts.meta.per_page,
          total: result.posts.meta.total
        });
      }
    }
  };

  return (
    <section className="bg-white py-4 lg:py-24 relative bg-left-top bg-no-repeat dark:bg-gradient-to-br dark:from-indigo2 dark:via-indigo2 dark:to-indigo3"
      style={{ backgroundImage: theme === 'light' ? `url(${Bg})` : '' }}
    >
      <div className="container px-4 mx-auto">
        <h2 className="font-bold text-3xl lg:text-4xl mt-14 lg:mt-8 mb-8 lg:mb-10 dark:text-white">
          Blog
        </h2>
        <div className="grid md:gap-8 lg:grid-cols-12">
          {
            loading ? <ImagePlaceholder/> :
              firstPost &&
              <>
                <div className="lg:col-span-8 mb-4">
                  <NavLink to={ `/${firstPost?.slug}` }>
                    <img className="rounded-xl" src={ firstPost.featured_image } alt={ firstPost.title } />
                  </NavLink>
                </div>
                <div className="lg:col-span-4 mb-4">
                  <div>
                    <p className="text-sm text-indigo3 mb-6">/ { firstPost.category?.name }</p>
                    <h2 className="text-2xl md:text-xl lg:text-2xl xl:text-4xl font-bold mb-4 dark:text-white">
                      <NavLink to={ `/${firstPost?.slug}` }>
                        { firstPost.title }
                      </NavLink>
                    </h2>
                    <p className="text-base md:text-xl text-gray-500 dark:text-gray-300">
                      { excerptString(firstPost.body, 200) }
                    </p>
                    <p className="text-sm text-gray-400 mt-6 lg:mt-6 xl:mt-10 dark:text-gray-300">
                      <ShowDate date={ firstPost.published_at } format="MMM DD, YYYY"/>
                    </p>
                  </div>
                </div>
              </>
          }
        </div>
        <div className="grid md:gap-4 md:grid-cols-6 lg:gap-8 lg:grid-cols-12 mt-8">
          {
            loading ? (
              <CardPlaceholder repeat={ 3 } classes="md:col-span-3 lg:col-span-4 mb-4"/>
            ) : (
              posts && posts.length > 0 ? (
                posts.map(post => (
                  <div
                    key={ post.id }
                    className="md:col-span-3 lg:col-span-4 flex flex-col mb-4"
                  >
                    <BlogCard post={ post }/>
                  </div>
                ))
              ) : (
                <Empty/>
              )
            )
          }
        </div>
      </div>
    </section>
  );
};
