import React from 'react';

// import HotBids from '../../components/Common/HotBids';
// import LiveAuction from '../../components/Common/LiveAuction';
import Hero from '../../components/Marketplace/Hero';
import MarketPlaceComp from '../../components/Marketplace/MarketPlace';
import SectionTwo from '../../components/Marketplace/SectionTwo';
// import TopOfTheWeek from '../../components/Marketplace/TopOfTheWeek';

export default function MarketPlace() {
  return (
    <>
      <Hero/>
      <SectionTwo/>
      { /* <HotBids/>
      <TopOfTheWeek/>
      <LiveAuction/> */ }
      <MarketPlaceComp/>
    </>
  );
};
