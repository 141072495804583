import { AxiosError } from 'axios';

import { RequestWrapper } from '../../../helpers/RequestWrapper';
import { User } from '../../../models/User';
import { PasswordRequestType } from '../../../models/User';
import API from '..';

const USER_ENDPOINT = 'account';

class UserAPI {

  static Profile = class {
    static get = (errorHandler: (error: Error) => void) => {
      return RequestWrapper(
        async () => 
          (await API.get(`${USER_ENDPOINT}/profile`)).data, errorHandler
      );
    };
    static updateProfile = (inputs: Partial<User>, errorHandler: (error: AxiosError) => void) => {
      return RequestWrapper(
        async () => 
          (await API.post(`${USER_ENDPOINT}/update-profile`, inputs)).data, errorHandler
      );
    };
    static updateContact = (inputs: Partial<User>, errorHandler: (error: AxiosError) => void) => {
      return RequestWrapper(
        async () => 
          (await API.post(`${USER_ENDPOINT}/update-contact`, inputs)).data, errorHandler
      );
    };
    static updatePassword = (inputs: PasswordRequestType, errorHandler: (error: AxiosError) => void) => {
      return RequestWrapper(
        async () => 
          (await API.post(`${USER_ENDPOINT}/update-password`, inputs)).data, errorHandler
      );
    };
    static updateCoverImage = (formData: FormData, errorHandler: (error: AxiosError) => void) => {
      return RequestWrapper(
        async () => 
          (await API.post(`${USER_ENDPOINT}/update-cover-image`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })).data, errorHandler
      );
    };
    static updateAvatarImage = (formData: FormData, errorHandler: (error: AxiosError) => void) => {
      return RequestWrapper(
        async () => 
          (await API.post(`${USER_ENDPOINT}/update-avatar-image`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })).data, errorHandler
      );
    };
    static deleteAccount = (errorHandler: (error: Error) => void) => {
      return RequestWrapper(
        async () => 
          (await API.post(`${USER_ENDPOINT}/delete`)).data, errorHandler
      );
    };
    static getTwoFactorAuthCodes = (password: string, errorHandler: (error: Error) => void) => {
      return RequestWrapper(
        async () => 
          (await API.post(`${USER_ENDPOINT}/2fa-codes`, { password: password })).data, errorHandler
      );
    };
    static enableTwoFactorAuth = (code: string, secret: string, errorHandler: (error: Error) => void) => {
      return RequestWrapper(
        async () => 
          (await API.post(`${USER_ENDPOINT}/enable-2fa`, { code: code, secret: secret })).data, errorHandler
      );
    };
    static disableTwoFactorAuth = (password: string, errorHandler: (error: Error) => void) => {
      return RequestWrapper(
        async () => 
          (await API.post(`${USER_ENDPOINT}/disable-2fa`, { password: password })).data, errorHandler
      );
    };
  };

  static Notifications = class {
    static getAll = (errorHandler: (error: Error) => void) => {
      return RequestWrapper(
        async () => {
          const notifications = await API.get(`${USER_ENDPOINT}/notifications`);
          return notifications?.data;
        }, 
        errorHandler
      );
    };

    static markAsRead = (notificationId: number, errorHandler: (error: Error) => void) => {   
      return RequestWrapper(
        async () => {
          const response = await API.get(`${USER_ENDPOINT}/notification/mark-as-read/${notificationId}`);
          return response.data;
        },
        errorHandler
      );
    };
  };

}

export { USER_ENDPOINT, UserAPI };
