import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MarketPlaceAPI } from '../../app/api/endpoints/Marketplace';
import Faq from '../../components/Nft/Faq';
import Hero from '../../components/Nft/Hero';
import IncludedPlayers from '../../components/Nft/IncludedPlayers';
import LatestActivities from '../../components/Nft/LatestActivities';
import SimilarPacks from '../../components/Nft/SimilarPacks';
import { handleError } from '../../helpers/Utils';
import { Collection } from '../../models/Collection';
import { Item } from '../../models/Item';

export default function Nft() {
  const { itemId } = useParams<{itemId: string}>();
  const [loading, setLoading] = useState<boolean>(false);
  const [collection, setCollection] = useState<Collection>();
  const [item, setItem] = useState<Item>();

  useEffect(() => {
    if(itemId) {
      getCollection();
    }
  }, [ itemId ]);

  const getCollection = async () => {
    setLoading(true);
    const { result } = await MarketPlaceAPI.getCollection(itemId, (error) => {
      setLoading(false);
      handleError(error);
    });

    if (result) {
      setLoading(false);
      if (result.status) {
        setCollection(result.collection);
        if(result.items && result.items.length > 0) {
          setItem(result.items[0]);
        }
      }
    }
  };

  return (
    <>
      <Hero collection={ collection } item={ item } loading={ loading } />
      <IncludedPlayers/>
      <LatestActivities/>
      <SimilarPacks/>
      <Faq/>
    </>
  );
};
