import React from 'react';
import { IoHeart } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';

import LogoIcon from '../../assets/img/logo_icon.svg';
import { LocalCollection } from '../../models/Collection';

interface CollectionCardProps {
  collection: LocalCollection
} 
export default function CollectionCard({ collection } : CollectionCardProps) {
  console.log(typeof collection?.collection_images?.collectionLogo);
  return (
    <div className="flex flex-col max-w-[360px] mx-auto">
      <div className="h-[300px] object-contain overflow-hidden">
        <NavLink to={ `/nft/${collection?.collection_id}` }>
          <img className="rounded-t-xl" src={ 
            typeof collection?.collection_images?.collectionLogo === 'string' ? collection?.collection_images?.collectionLogo
              : collection?.collection_images?.collectionLogo?.blobUrl
          } alt={ collection?.collection_name } />
        </NavLink>
      </div>
      <div className="flex flex-col rounded-b-xl bg-gray-200 p-3">
        <h4 className="text-2xl sm:text-xl md:text-2xl">
          <NavLink to={ `/nft/${collection?.collection_id}` }>
            { collection?.collection_name }
          </NavLink>
        </h4>
        {
          collection?.club &&
            <div className="flex gap-2 items-center mb-2">
              <img className="w-6" src={ collection?.club?.logo } alt={ collection?.club?.name } />
              <p className="text-xl text-grey">@{ collection?.club?.slug }</p>
            </div>
        }
        <div className="flex gap-2 items-center justify-between">
          <div>
            <h4 className="text-2xl sm:text-xl md:text-2xl text-bold leading-none mb-0">
              <img className="w-8 h-10 inline-block" src={ LogoIcon } alt="Goall" /> ${ collection.collection_price }/NFT
            </h4>
          </div>
          <p className="text-sm inline-flex items-center"><IoHeart className="mr-1"/> { 20 }</p>
        </div>
      </div>
    </div>
  );
};
