import React, { useState } from 'react';

import { UserAPI } from '../../../app/api/endpoints/User';
import { handleError } from '../../../helpers/Utils';
import { Alert, Button, Input } from '../../Common/ui';

interface LinkDeviceProps {
  gotToNext: (args: { recoveryCodes?: string[] }) => void;
  secret: string | undefined;
  qrCode: {
    svg: string;
    url: string;
  } | undefined;
}
export default function LinkDevice({ secret, qrCode, gotToNext }: LinkDeviceProps) {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState<string | undefined>(undefined);
  const [error , setError] = useState<string | undefined>(undefined);

  const onSubmit = async () => {
    setError(undefined);
    if (code && secret) {
      if(!isNaN(parseInt(code))) {
        setLoading(false);
        const { result } = await UserAPI.Profile.enableTwoFactorAuth(code, secret, (error) => {
          setLoading(false);
          setError(handleError(error));
        });
      
        if (result) {
          if (result.status) {
            gotToNext({
              recoveryCodes: result.recoveryCodes,
            });
          } else {
            setError(handleError(result.code));
          }
          setLoading(false);
        }
      } else {
        setError('Code should be a valid 6 digits.');
      }
    } else {
      setError('6 digits code required.');
    }
  };
  return (
    <>
      <h3 className="text-lg md:text-xl font-bold text-center dark:text-white">Set up with authenticator app</h3>
      <p className="text-base font-bold text-center mt-3 dark:text-white">
        Scan QR code on app
      </p>
      <p className="text-sm text-center dark:text-white">
        Scan the QR code with your authenticator app to generate a unique code.
      </p>

      <div className="mt-2 flex items-center justify-center mb-2">
        <img src={ qrCode?.svg } alt="" />
      </div>
      <div>
        { error &&
          <Alert variant="danger">{ error }</Alert>
        }
        <p className="text-base mb-3 dark:text-white">Verify code</p>
        <Input
          type="text"
          name="code"
          label="Code"
          placeholder="Enter 6 digits..."
          required
          value={ code }
          onChange={ (e) => setCode(e.target.value) }
        />
      </div>
      <div className="flex items-center justify-end mt-6">
        <Button
          className="btn btn-primary float-right"
          loading={ loading }
          onClick={ onSubmit }
        >
          Verify & Activate 2fa
        </Button>
      </div>
    </>
  );
}
