import React, { useState } from 'react';

import { successAlert } from '../../../helpers/Utils';
import { Steps, StepsItem } from '../../Common/Steps';
import LinkDevice from './LinkDevice';
import Requiremets from './Requiremets';
import SaveRecovryCodes from './SaveRecovryCodes';

interface TwoFaData {
  secret?: string;
  qrCode?: {
    svg: string;
    url: string;
  };
  recoveryCodes?: string[];
}
export default function TwoFaSteps({ closeModal }: { closeModal: () => void }) {
  const [step, setStep] = useState<number>(1);
  const [secret, setSecret] = useState<string>('');
  const [qrCode, setqrCode] = useState<TwoFaData['qrCode']>({
    svg: '',
    url: ''
  });
  const [recoveryCodes, setRecoveryCodes] = useState<string[]>([]);

  const gotToNext = (data: TwoFaData) => {
    if(data.secret && data.qrCode) {
      setSecret(data.secret);
      setqrCode(data.qrCode);
    }
    if(data.recoveryCodes && data.recoveryCodes?.length>0) {
      setRecoveryCodes(data.recoveryCodes);
    }
    setStep(prevState => prevState + 1);
  };
  
  const onFinish = () => {
    successAlert('2FA has been activated successfully');
    closeModal();
  };

  return (
    <div>
      <Steps>
        <StepsItem id={ 1 } title="Requirements" current={ step === 1 } done={ step > 1 }/>
        <StepsItem id={ 2 } title="Link device" current={ step === 2 } done={ step > 2 }/>
        <StepsItem id={ 3 } title="Recovery codes" current={ step === 3 } done={ step > 3 }/>
      </Steps>
      <div className="mt-6">
        {
          step === 1 &&
          <Requiremets
            gotToNext={ (args) => gotToNext(args) }
          />
        }
        {
          step === 2 &&
          <LinkDevice
            secret={ secret }
            qrCode={ qrCode }
            gotToNext={ (args) => gotToNext(args) }
          />
        }
        {
          step === 3 &&
          <SaveRecovryCodes
            recoveryCodes={ recoveryCodes }
            onFinish={ () => onFinish() }
          />
        }
      </div>
    </div>
  );
}
