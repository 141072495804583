import React from 'react';
import { NavLink } from 'react-router-dom';

import { excerptString } from '../../helpers/Utils';
import { Post } from '../../models/Blog';
import ShowDate from '../Common/ShowDate';

interface BlogCardProps {
  post: Post
}

export default function BlogCard({ post }: BlogCardProps) {
  return (
    <div className="bg-grey2 rounded-lg flex-1 flex flex-col">
      <NavLink to={ `/${post.slug}` }>
        <img className="w-full rounded-t-lg" src={ post.featured_image } alt={ post.title } />
      </NavLink>
      <div className="p-4">
        <span className="text-sm text-violet">/ { post.category?.name }</span>
        <h3 className="text-lg leading md:text-xl lg:text-2xl">
          <NavLink to={ `/${post.slug}` }>
            { excerptString(post.title, 75, false) }
          </NavLink>
        </h3>
        <p className="text-sm md:text-base">
          { post.excerpt }
        </p>
      </div>
      <p className="flex flex-1 items-end text-sm text-gray-400 px-4 pb-4">
        <ShowDate date={ post.published_at } format="MMM DD, YYYY"/>
      </p>
    </div>
  );
};
