import { useConnectWallet } from '@web3-onboard/react';
import React, { useState } from 'react';

import Bg from '../../assets/img/bg/green_grad.png';
import DeleteAccount from '../../components/Account/DeleteAccount';
import EditContact from '../../components/Account/EditContact';
import EditPassword from '../../components/Account/EditPassword';
import EditProfile from '../../components/Account/EditProfile';
import TwoFactorAuth from '../../components/Account/TwoFactorAuth';
import { Accordion, AccordionBody, AccordionHeader } from '../../components/Common/Accordion';
import WalletConnect from '../../components/Wallet/WalletConnect';
import { useAuth } from '../../providers/auth/AuthProvider';
import { useTheme } from '../../providers/ThemeProvider';

export default function Settings() {
  const { theme } = useTheme();
  const [ { wallet } ] = useConnectWallet();

  const { profile, updateProfile, twoFactorStatus } = useAuth();
  const [open, setOpen] = useState(1);
 
  const handleOpen = (value: number) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <section className="bg-white py-14 lg:py-24 relative bg-left-top bg-no-repeat dark:bg-gradient-to-br dark:from-indigo2 dark:via-indigo2 dark:to-indigo3"
      style={{ backgroundImage: theme === 'light' ? `url(${Bg})` : '' }}
    >
      <div className="container px-4 mx-auto">
        <h3 className="text-center text-2xl md:text-4xl font-bold mb-10 dark:text-white md:mt-10">Account Setting</h3>
        <div className="max-w-xl mx-auto">
          <div className="p-8 mb-6 rounded-lg border border-grey">
            <Accordion>
              <AccordionHeader
                onPress={ () => handleOpen(1) }  
                open={ open === 1 }
              >
                Profile
              </AccordionHeader>
              <AccordionBody open={ open === 1 }>
                <EditProfile
                  profile={ profile }
                  updateProfile={ updateProfile }
                />
              </AccordionBody>
            </Accordion>
          </div>
          <div className="p-8 mb-6 rounded-lg border border-grey">
            <Accordion>
              <AccordionHeader
                onPress={ () => handleOpen(2) }  
                open={ open === 2 }
              >
                Contact information
              </AccordionHeader>
              <AccordionBody open={ open === 2 }>
                <EditContact
                  profile={ profile }
                  updateProfile={ updateProfile }
                />
              </AccordionBody>
            </Accordion>
          </div>
          <div className="p-8 mb-6 rounded-lg border border-grey">
            <Accordion>
              <AccordionHeader
                onPress={ () => handleOpen(3) }  
                open={ open === 3 }
              >
                Password
              </AccordionHeader>
              <AccordionBody open={ open === 3 }>
                <EditPassword/>
              </AccordionBody>
            </Accordion>
          </div>
          <div className="p-8 mb-6 rounded-lg border border-grey">
            <Accordion>
              <AccordionHeader
                onPress={ () => handleOpen(4) }  
                open={ open === 4 }
              >
                <>
                  Wallet
                  { 
                    wallet &&
                      <span className="text-xs font-normal text-white px-2 py-[2px] ml-2 bg-green-400 rounded">Connected</span>
                  }
                </>
              </AccordionHeader>
              <AccordionBody open={ open === 4 }>
                {
                  wallet ? (
                    <p>Wallet: { wallet?.accounts[0]?.address }</p>
                  ) : (
                    <p>No external wallet connected</p>
                  )
                }
                <WalletConnect/>
              </AccordionBody>
            </Accordion>
          </div>
          <div className="p-8 mb-6 rounded-lg border border-grey">
            <Accordion>
              <AccordionHeader
                onPress={ () => handleOpen(5) }  
                open={ open === 5 }
              >
                <>
                  Two factor authentication
                  { 
                    twoFactorStatus &&
                      <span className="text-xs font-normal text-white px-2 py-[2px] ml-2 bg-green-400 rounded">Active</span>
                  }
                </>
              </AccordionHeader>
              <AccordionBody open={ open === 5 }>
                <TwoFactorAuth/>
              </AccordionBody>
            </Accordion>
          </div>
          <div className="p-8 mb-6 rounded-lg border border-grey">
            <Accordion>
              <AccordionHeader
                onPress={ () => handleOpen(6) }  
                open={ open === 6 }
              >
                Identity verification (KYC)
              </AccordionHeader>
              <AccordionBody open={ open === 6 }>
                <p>Account unverified</p>
              </AccordionBody>
            </Accordion>
          </div>
          <div className="p-8 mb-6 rounded-lg border border-grey">
            <Accordion>
              <AccordionHeader
                onPress={ () => handleOpen(7) }  
                open={ open === 7 }
              >
                Close account
              </AccordionHeader>
              <AccordionBody open={ open === 7 }>
                <DeleteAccount/>
              </AccordionBody>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
}
