// @ts-ignore
import _ from 'lodash';
// @ts-ignore
import Validator from 'validator';

import { User } from '../models/User';
import { EMAIL_NOT_VALID, NUMERIC, REQUIRED } from './messages';


export default function validateInput(data: Partial<User>) {
  const errors = {
    name: '',
    username: '',
    address: '',
    zip_code: '',
    country: '',
    city: '',
  };

  if (!data.name || Validator.isEmpty(data.name)) {
    errors.name = REQUIRED;
  }

  if (!data.username || !/^[a-z0-9_.]+$/.test(data.username!)) {
    errors.username = EMAIL_NOT_VALID;
  }

  if (!data.username || Validator.isEmpty(data.username)) {
    errors.username = REQUIRED;
  }

  if (!data.address || Validator.isEmpty(data.address)) {
    errors.address = REQUIRED;
  }

  if (!data.zip_code || isNaN(Number(data.zip_code))) {
    errors.zip_code = NUMERIC;
  }

  if (!data.zip_code || Validator.isEmpty(data.zip_code+'')) {
    errors.zip_code = REQUIRED;
  }

  if (!data.country || data.country === '') {
    errors.country = REQUIRED;
  }

  if (!data.city || data.city === '') {
    errors.city = REQUIRED;
  }

  return {
    errors,
    isValid: _.values(errors).every(_.isEmpty)
  };
}
