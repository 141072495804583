import React from 'react';

import FutureSection from '../../components/About/FutureSection';
import Hero from '../../components/About/Hero';
import SectionTwo from '../../components/About/SectionTwo';
import Team from '../../components/Common/Team';

export default function About() {
  return (
    <>
      <Hero/>
      <SectionTwo/>
      <FutureSection/>
      <Team/>
    </>
  );
}
