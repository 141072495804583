import React from 'react';
import { Outlet } from 'react-router-dom';

import ScrollToTop from '../../helpers/ScrollToTop';
import Footer from './Footer';
import Header from './Header';

export default function Layout() {
  return (
    <div>
      <ScrollToTop/>
      <Header/>
      <main>
        <Outlet />
      </main>
      <Footer/> 
    </div>
  );
};
