import React, { useState } from 'react';
import { IoEyeOffSharp, IoEyeSharp } from 'react-icons/io5';

import { UserAPI } from '../../app/api/endpoints/User';
import { handleError, successAlert } from '../../helpers/Utils';
import { Alert, Button, Input } from '../Common/ui';

interface RequirementsProps {
  closeDisableModal: () => void
}
export default function DisableTwoFa({ closeDisableModal }: RequirementsProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [error , setError] = useState<string | undefined>(undefined);

  const onSubmit = async () => {
    setError(undefined);
    if (password.length > 0) {
      setLoading(true);
      const { result } = await UserAPI.Profile.disableTwoFactorAuth(password, (error) => {
        setLoading(false);
        setError(handleError(error));
      });
    
      if (result) {
        if (result.status) {
          successAlert('Two Factor Authenetication disabled.');
          closeDisableModal();
        } else {
          setError(handleError(result.code));
        }
        setLoading(false);
      }
    } else {
      setError('Password required.');
    }
  };

  return (
    <>
      <div className="mt-6">
        <h4 className="text-xl font-bold text-center dark:text-white">Disable Two Factor Authentication</h4>
        { error &&
          <Alert variant="danger">{ error }</Alert>
        }
        <p className="text-sm text-gray-500 dark:text-white">
          For security reasons, you will need to re-enter your password.
        </p>
        <div className="relative mt-4">
          <Input
            type={ showPassword ? 'text' : 'password' }
            name="password"
            label="Password"
            placeholder="Enter your Password..."
            required
            value={ password }
            onChange={ (e) =>  setPassword(e.target.value) }
          />
          <div
            className="cursor-pointer absolute right-4 top-4"
            onClick={ () => setShowPassword(!showPassword) }
          >
            { showPassword ? (
              <IoEyeOffSharp className="h-6 w-6 font-extralight" />
            ) : (
              <IoEyeSharp className="h-6 w-6 font-extralight" />
            ) }
          </div>
        </div>
        <div className="flex items-center justify-end mt-6">
          <Button
            className="btn btn-primary float-right"
            loading={ loading }
            spinnerclass="text-white"
            onClick={ onSubmit }
          >
            Verify & Disable
          </Button>
        </div>
      </div>
    </>
  );
}
