import React from 'react';
import { NavLink } from 'react-router-dom';

import ExpImg from '../../assets/img/home/exp.png';
import Badge from '../../assets/img/vector/badge.svg';
import VecImg from '../../assets/img/vector/vector_2.svg';

export default function SectionTwo() {
  return (
    <section className="bg-white py-3 lg:py-24 dark:bg-gradient-to-tr dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="container px-4 py-4 mx-auto">
        <div className="rounded-xl bg-indigo p-8">
          <div className="grid lg:gap-8 lg:grid-cols-12">
            <div className="lg:col-span-3 mb-4">
              <p className="text-white text-3xl lg:text-[40px] lg:leading-[48px]">
                Collect & sell your NFTs
              </p>
            </div>             
            <div className="lg:col-span-3 mb-4">
              <div className="text-white border-b pb-3 lg:pb-0 lg:border-b-0 border-gray-100 lg:border-r lg:border-opacity-30 lg:pr-3">
                <h3 className="mb-4 text-2xl lg:text-3xl">Buy</h3>
                <p className="text-base font-sf-pro-text font-thin">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
            </div>             
            <div className="lg:col-span-3 mb-4">
              <div className="text-white border-b pb-3 lg:pb-0 lg:border-b-0 lg:border-r border-gray-100 lg:border-opacity-30 lg:pr-3">
                <h3 className="mb-4 text-2xl lg:text-3xl">Player Transfer</h3>
                <p className="text-base font-sf-pro-text font-thin">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
            </div>             
            <div className="lg:col-span-3 mb-4">
              <div className="text-white">
                <h3 className="mb-4 text-2xl lg:text-3xl">Sell</h3>
                <p className="text-base font-sf-pro-text font-thin">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
            </div>             
          </div>
        </div>
        <div className="grid lg:gap-8 lg:grid-cols-12 mt-16">
          <div className="lg:col-span-6 mb-4">
            <div className="relative p-8">
              <img className="w-16 absolute top-0 left-0" src={ Badge } />
              <img className="rounded-xl" src={ ExpImg } />
            </div>
          </div>
          <div className="lg:col-span-6 flex flex-col justify-center">
            <h2 className="mb-8 md:max-w-lg text-3xl sm:text-4xl font-semibold tracking-tight leading-16 md:text-5xl xl:text-[60px] xl:leading-[70px] dark:text-white">
              Experience the <span className="inline-block relative">Future <img className="absolute left-0 bottom-0 w-24 md:w-36 lg:w-48" src={ VecImg } /></span> of Football Collectibles!
            </h2>
            <p className="text-base font-sf-pro-text font-thin md:max-w-lg mb-8 dark:text-white">
            Here, you can browse and buy unique, one-of-a-kind digital collectibles featuring your favorite football players and teams. These non-fungible tokens (NFTs) are verified on the blockchain, ensuring their authenticity and rarity.
Start your collection today and own a piece of football history!
            </p>
            <NavLink to="/marketplace" className="w-[170px] inline-flex items-center justify-center px-5 py-3 mr-3 mb-3 text-base font-bold text-center text-white rounded-lg bg-malacite hover:bg-primary-800">
              Explore
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};
