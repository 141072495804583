import React, { ReactNode } from 'react';
import { IoAlertCircle, IoCheckmarkCircle } from 'react-icons/io5';

interface AlertProps {
  children?: ReactNode;
  variant: 'info' | 'success' | 'danger' | 'warning';
}

export default function Alert(props: AlertProps) {
  switch(props.variant) {
  case 'info':
    return (
      <div id="alert-1" className="flex p-4 mb-6 text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
        <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
        <span className="sr-only">Info</span>
        <div className="ml-3 text-sm font-medium">
          { props.children }
        </div>
      </div>
    );
    break;
  case 'success':
    return(
      <div id="alert-3" className="flex p-4 mb-6 text-green-800 rounded-lg bg-green-100 dark:bg-gray-800 dark:text-green-400" role="alert">
        <IoCheckmarkCircle className="flex-shrink-0 w-5 h-5"/>
        <span className="sr-only">Info</span>
        <div className="ml-3 text-sm font-medium">
          { props.children }
        </div>
      </div>
    );
    break;
  case 'danger':
    return(
      <div id="alert-2" className="flex p-4 mb-6 text-red-800 rounded-lg bg-red-100 dark:bg-gray-800 dark:text-red-400" role="alert">
        <IoAlertCircle className="flex-shrink-0 w-5 h-5"/>
        <span className="sr-only">Danger</span>
        <div className="ml-3 text-sm font-medium">
          { props.children }
        </div>
      </div>
    );
    break;
  case 'warning':
    return (
      <div id="alert-4" className="flex p-4 mb-6 text-yellow-800 rounded-lg bg-yellow-100 dark:bg-gray-800 dark:text-yellow-300" role="alert">
        <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
        <span className="sr-only">Info</span>
        <div className="ml-3 text-sm font-medium">
          { props.children }
        </div>
      </div>
    );
    break;
  default:
    return (
      <></>
    );
  }
};
