// @ts-ignore
import _ from 'lodash';
// @ts-ignore
import Validator from 'validator';

import { PasswordRequestType } from '../models/User';
import { CONFIRMED, PASSWORD_MIN,REQUIRED } from './messages';

export default function validateInput(data: PasswordRequestType) {
  const errors = {
    current_password: '',
    password: '',
    password_confirmation: ''
  };

  if (data.current_password.length<8) {
    errors.current_password = PASSWORD_MIN;
  }

  if (Validator.isEmpty(data.current_password)) {
    errors.current_password = REQUIRED;
  }

  if (data.password.length<8) {
    errors.password = PASSWORD_MIN;
  }

  if (Validator.isEmpty(data.password)) {
    errors.password = REQUIRED;
  }

  if (data.password_confirmation.length<8) {
    errors.password_confirmation = PASSWORD_MIN;
  }

  if (data.password_confirmation !== data.password) {
    errors.password_confirmation = CONFIRMED;
  }

  if (Validator.isEmpty(data.password_confirmation)) {
    errors.password_confirmation = REQUIRED;
  }

  return {
    errors,
    isValid: _.values(errors).every(_.isEmpty)
  };
}
