import React from 'react';

import Didier from '../../assets/img/team/didier.png';
import Laurent from '../../assets/img/team/laurent.png';
import Michael from '../../assets/img/team/michael.png';

export default function Team() {
  return (
    <section className="bg-white py-3 lg:pt-6 lg:pb-24 dark:bg-gradient-to-b dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="container px-4 py-4 mx-auto">
        <h2 className="text-4xl font-bold text-center mb-8 dark:text-white">
          Meet our team
        </h2>
        <div className="flex flex-col items-center justify-center sm:flex-row sm:justify-around gap-8">
          <div className="max-w-[216px] text-center hover:scale-105 duration-150 ease-in-out">
            <img className="rounded-lg" src={ Laurent } alt="Laurent" />
            <h2 className="text-xl md:text-3xl font-bold mt-3 dark:text-white">
              Laurent <br /> Gutsmuth
            </h2>
            <p className="text-base sm:text-sm font-bold text-malacite mt-2">
              FOUNDER - CRP - CPO
            </p>
          </div>                        
          <div className="max-w-[216px] text-center hover:scale-105 duration-150 ease-in-out">
            <img className="rounded-lg" src={ Michael } alt="Michael" />
            <h2 className="text-xl md:text-3xl font-bold mt-3 dark:text-white">
              Michael <br /> Ohayon
            </h2>
            <p className="text-base sm:text-sm font-bold text-malacite mt-2">
              FOUNDER - CEO
            </p>
          </div>                        
          <div className="max-w-[216px] text-center hover:scale-105 duration-150 ease-in-out">
            <img className="rounded-lg" src={ Didier } alt="Didier" />
            <h2 className="text-xl md:text-3xl font-bold mt-3 dark:text-white">
              Didier <br /> Nizard
            </h2>
            <p className="text-base sm:text-sm font-bold text-malacite mt-2">
              CTO
            </p>
          </div>                        
        </div>
      </div>
    </section>
  );
};
