import React, { useState } from 'react';

import Bg from '../../assets/img/bg/green_grad.png';
import Gallery from '../../components/Account/Gallery';
import PaymentHistory from '../../components/Account/PaymentHistory';
import ProfileHeader, { ProfileMenu } from '../../components/Account/ProfileHeader';
import { useAuth } from '../../providers/auth/AuthProvider';
import { useTheme } from '../../providers/ThemeProvider';

export default function Profile() {
  const { theme } = useTheme();
  const { profile, logout } = useAuth();
  const [menu, setMenu] = useState<ProfileMenu>(ProfileMenu.Gallery);

  return (
    <section className="bg-white py-14 lg:py-24 relative bg-left-top bg-no-repeat dark:bg-gradient-to-br dark:from-indigo2 dark:via-indigo2 dark:to-indigo3"
      style={{ backgroundImage: theme === 'light' ? `url(${Bg})` : '' }}
    >
      <div className="container px-4 mx-auto">
        <ProfileHeader
          profile={ profile }
          logout={ logout }
          onMenuChange={ (menu) => setMenu(menu) }
        />
        {
          menu === ProfileMenu.Gallery &&
          <Gallery/>
        }
        {
          menu === ProfileMenu.PaymentHistory &&
          <PaymentHistory/>
        }
      </div>
    </section>
  );
}
