import React, { InputHTMLAttributes, useState } from 'react';
import { Popover } from 'react-tiny-popover';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string,
  error?: string, 
  hasPopover?: boolean,
  popoverContent?: JSX.Element
}

export default function Input(props: InputProps) {
  const { required, label, hasPopover, popoverContent, ...rest } = props;
  const [showPopover, setShowPopover] = useState(false);
  return (
    <div className="w-full relative mb-6">
      <Popover
        isOpen={ showPopover }
        positions={ [ 'top' ] }
        align="end"
        content={ hasPopover ? popoverContent! : <></> }
      >
        <input
          { ...rest }
          id={ props.name }
          autoComplete={ props.type === 'password' ? 'off' : 'on' }
          onFocus={ () => {
            if(hasPopover) {
              setShowPopover(true);
            }
          } }
          onBlur={ () => {
            if(hasPopover) {
              setShowPopover(false);
            }
          } }
          className="block px-2.5 pb-2.5 pt-2 h-14 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-dark2 appearance-none dark:text-white dark:border-white focus:outline-none ring-0 disabled:cursor-not-allowed"
        />
      </Popover>
      <label htmlFor={ props.name } className="absolute text-base text-black font-semibold bg-white dark:bg-indigo2 dark:text-white duration-300 transform z-2 origin-[0] px-2 top-1 scale-75 -translate-y-4 left-2">
        {
          label
        } { required && <span className="text-red-400">*</span> }
      </label>
      {
        props.error &&
        <p className="mt-1 text-sm text-red-600 dark:text-red-400">{ props.error }</p>
      }
    </div>
  );
};
