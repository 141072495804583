import { Token } from '../models/Auth';


export const destroyToken = async () => {
  localStorage.removeItem('ga_token');
};

export const storeToken = async (token: Token) => {
  localStorage.setItem('ga_token', JSON.stringify(token));
};

export const verifyToken = async () => {
  const cached = localStorage.getItem('ga_token');
  if(cached) {
    const token = JSON.parse(cached.trim());
    const expires_at = new Date(token.expires_at).getTime();
    if (expires_at >= Date.now()) {
      return token;
    } return undefined;
  }
};
