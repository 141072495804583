import React from 'react';

export default function NotFound() {
  return (
    <div className="h-[calc(100vh-64px)] w-full mt-[46px] lg:mt-16 p-4 flex items-center justify-center bg-gradient-to-bl from-indigo2 via-indigo2 to-indigo3">
      <div className="w-full max-w-2xl text-center">
        <p className="text-xl text-white">404 error</p>
        <h2 className="text-2xl sm:text-4xl md:text-6xl my-8 text-white">This Is Not the page you are looking for.</h2>
        <p className="text-sm sm:text-lg md:text-xl text-white">
          Sorry, that page can&apos;t be found. But don&apos;t worry, our friendly experts at GOALL are here to help. Just reach out if you have any questions or need support.
        </p>
      </div>
    </div>
  );
}
