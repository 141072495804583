import React from 'react';

interface DividerProps {
  text?: string
}

export default function Divider({ text }: DividerProps) {
  return (
    <div className="inline-flex items-center justify-center w-full">
      {
        text ?
          <p
            className="w-full text-center overflow-hidden relative capitalize my-6 font-medium text-gray-900 dark:text-white 
            after:content-[''] after:h-px after:bg-gray-300 after:absolute after:top-1/2 after:right-0 after:w-[27%] md:after:w-1/3 after:ml-6
            before:content-[''] before:h-px before:bg-gray-300 before:absolute before:top-1/2 before:left-0 before:w-[27%] md:before:w-1/3 before:mr-6"
          >
            { text }
          </p>
          :
          <hr className="w-full h-px my-6 bg-gray-200 border-0 dark:bg-white"/>
      }
    </div>
  );
};
