import 'react-multi-carousel/lib/styles.css';

import React from 'react';
import { IoChevronForwardOutline } from 'react-icons/io5';
import Carousel from 'react-multi-carousel';

import PlayerCard from '../Cards/PlayerCard';

export default function HotBids() {
  const players = [
    {
      id: 1,
      name: 'MGOM-KBAAR',
      username: '@phoenix',
      price: 162.2,
      supply: '290/6000',
      raiting: 10,
      clubIcon: 'club_1',
      image: 'player_1',
    },
    {
      id: 2,
      name: 'STAM-DFACR',
      username: '@thunders',
      price: 142,
      supply: '509/6000',
      raiting: 20,
      clubIcon: 'club_2',
      image: 'player_2',
    },
    {
      id: 3,
      name: 'NJKL-MCVAD',
      username: '@blazecity',
      price: 104.7,
      supply: '5400/6000',
      raiting: 18,
      clubIcon: 'club_3',
      image: 'player_3',
    },
    {
      id: 4,
      name: 'CVOM-LMAAV',
      username: '@fctornado',
      price: 106.3,
      supply: '4900/6000',
      raiting: 14,
      clubIcon: 'club_4',
      image: 'player_4',
    }
  ];

  return (
    <section className="bg-white py-6 lg:py-14 dark:bg-gradient-to-b dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="container px-4 py-4 mx-auto">
        <div className="flex justify-between">
          <h3 className="text-2xl md:text-4xl lg:text-5xl font-semibold mb-8 dark:text-white">Hot Bids</h3>
          <div className="lg:col-span-12 text-center">
            <a href="#" className="text-base underline dark:text-white">See All <IoChevronForwardOutline className="inline-block text-xl"/></a>
          </div>
        </div>
        <Carousel
          additionalTransfrom={ 0 }
          arrows
          autoPlay
          autoPlaySpeed={ 3000 }
          draggable
          focusOnSelect={ false }
          infinite
          keyBoardControl
          minimumTouchDrag={ 80 }
          partialVisible
          pauseOnHover
          renderArrowsWhenDisabled={ false }
          renderButtonGroupOutside={ false }
          renderDotsOutside={ false }
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 768
              },
              items: 3,
              partialVisibilityGutter: 70
            },
            mobile: {
              breakpoint: {
                max: 639,
                min: 0
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 768,
                min: 640
              },
              items: 2,
              partialVisibilityGutter: 10
            }
          }}
          rewind={ false }
          rewindWithAnimation={ false }
          rtl={ false }
          shouldResetAutoplay
          showDots={ false }
          slidesToSlide={ 1 }
          swipeable
          itemClass="mr-1 sm:mr-4"
        >
          {
            players.map(player => (
              <PlayerCard
                key={ player.id }
                player={ player }
              />
            ))
          }
        </Carousel>
      </div>
    </section>
  );
};
