import React, { FormEvent, useEffect, useState } from 'react';

import { UserAPI } from '../../app/api/endpoints/User';
import { format422Error, handleError } from '../../helpers/Utils';
import { User } from '../../models/User';
import validateInput from '../../validators/profile-contact';
import { Alert, Button, Input } from '../Common/ui';

interface EditContactProps {
  profile: User | undefined;
  updateProfile: (user: User) => void
}
type ContactInputs = Partial<User>

export default function EditContact({ profile, updateProfile }: EditContactProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<ContactInputs>({} as ContactInputs);
  const [inputs, setInputs] = useState<ContactInputs>({
    email: '',
    phone: ''
  });
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);

  useEffect(() => {
    if(profile) {
      setInputs({
        email: profile.email,
        phone: profile.phone
      });
    }
  }, [ profile ]);

  const onInputsChange = (e: FormEvent<HTMLInputElement>) => {
    setInputs({ ...inputs, [e.currentTarget.name]: e.currentTarget.value });
    setErrors({
      ...errors,
      [e.currentTarget.name]: undefined
    });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors({} as ContactInputs);
    setError(undefined);
    setSuccess(undefined);
    if (isValid()) {
      setLoading(true);
      const { result } = await UserAPI.Profile.updateContact(inputs, (error) => {
        setLoading(false);
        if (error && error.response && error.response?.status == 422) {
          // @ts-ignore
          setErrors(format422Error(error?.response?.data?.errors));
        } else {
          setError(handleError(error));
        }
      });
  
      if (result) {
        if (result.status) {
          updateProfile(result.profile);
          setSuccess('Profile updated Successfully');
        } else {
          setError(handleError(result.code));
        }
        setLoading(false);
      }
    }
  };

  const isValid = () => {
    const { errors, isValid } = validateInput(inputs);
    if (!isValid) {
      setErrors(errors);
    }
    return isValid;
  };

  return (
    <div className="mt-4">
      { success &&
        <Alert variant="success">{ success }</Alert>
      }
      { error &&
        <Alert variant="danger">{ error }</Alert>
      }
      <form onSubmit={ (e) => onSubmit(e) }>
        <Input
          type="email"
          name="email"
          label="Email"
          placeholder="Enter your email..."
          required
          value={ inputs.email }
          disabled
        />
        <Input
          type="text"
          name="phone"
          label="Phone"
          placeholder="Enter your phone..."
          required
          value={ inputs.phone }
          onChange={ (e) =>  onInputsChange(e) }
          error={ errors && errors.phone }
        />
        <Button
          type="submit"
          className="btn btn-light btn-block mt-4"
          loading={ loading }
          spinnerclass="text-white"
        >
          save changes
        </Button>
      </form>
    </div>
  );
}
