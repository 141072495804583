import React, { useEffect, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import { IoLockClosedOutline } from 'react-icons/io5';
import { IoPersonCircleOutline } from 'react-icons/io5';
import { NavLink, useLocation } from 'react-router-dom';

import Logo from '../../assets/img/logo.svg';
import LogoWhite from '../../assets/img/logo_white.svg';
import { useScrollPosition } from '../../helpers/hooks/use-scroll-position';
import { useAuth } from '../../providers/auth/AuthProvider';

export default function Header() {
  const scrollPosition = useScrollPosition();
  const { pathname } = useLocation();
  const { isConnected } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isHero, setIsHero] = useState(false);
  const [isHeaderBgWhite, setIsHeaderBgWhite] = useState(false);

  useEffect(() => {
    if(
      pathname === '/'
    ) {
      setIsHero(true);
    } else setIsHero(false);
    if(
      pathname.startsWith('/login')
      || pathname.startsWith('/register')
      || pathname.startsWith('/email-verification')
      || pathname.startsWith('/forget-password')
      || pathname.startsWith('/reset-password')
      || pathname.startsWith('/marketplace')
      || pathname.startsWith('/about')
    ) {
      setIsHeaderBgWhite(true);
    } else setIsHeaderBgWhite(false);
  }, [ pathname ]);

  return (
    <header className={ `top-0 left-0 right-0 z-[999] ${scrollPosition > 0 ? 'fixed bg-white shadow' : `fixed ${isOpen && 'bg-white shadow-xl'} ${isHero? 'lg:bg-transparent' : `${isHeaderBgWhite && 'bg-white'}`}`} dark:bg-indigo2` }>
      <div className="container">
        <nav className="bg-transparent border-gray-200 px-4 lg:px-6 py-3">
          <div className="flex flex-wrap gap-2 lg:gap-24 justify-between items-center">
            <NavLink to="/" className="flex items-center">
              <img src={ Logo } className="self-center h-[22px] dark:hidden" alt="Goall" />
              <img src={ LogoWhite } className="self-center h-[22px] hidden dark:block" alt="Goall" />
            </NavLink>
            <div className="flex items-center justify-between lg:order-2">
              <span className={ `hidden lg:flex cursor-pointer ${scrollPosition>0?'text-gray-700 dark:text-gray-200 dark:hover:text-white': `${isHero?'text-white':'text-gray-700 dark:text-white'}`} font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2` }>
                <IoSearch className="text-[18px]"/>
              </span>
              {
                isConnected ?
                  <NavLink to="/account" className={ `hidden lg:flex items-center ${scrollPosition>0?'text-gray-700 dark:text-gray-200 lg:dark:hover:text-white': `${isHero?'text-white':'text-gray-700 dark:text-white'}`} font-medium rounded-lg text-sm pl-4 lg:pl-5 py-2 lg:py-2.5` }>
                    <IoPersonCircleOutline className="text-[18px] mr-2"/> My Account
                  </NavLink> :
                  <NavLink to="/login" className={ `hidden lg:flex items-center ${scrollPosition>0?'text-gray-700 dark:text-gray-200 lg:dark:hover:text-white': `${isHero?'text-white':'text-gray-700 dark:text-white'}`} font-medium rounded-lg text-sm pl-4 lg:pl-5 py-2 lg:py-2.5` }>
                    <IoLockClosedOutline className="text-[18px] mr-2"/> Sign In
                  </NavLink>
              }
              <button
                className="flex w-5 h-5 items-center ml-1 text-sm text-dark2 lg:hidden dark:text-white"
                onClick={ () => setIsOpen(!isOpen) }
              >
                <span className="sr-only">Open main menu</span>
                <div className="block w-5 transform">
                  <span className={ `block absolute h-0.5 w-5 dark:text-white bg-current transform transition duration-500 ease-in-out ${ isOpen ? 'rotate-45' : '-translate-y-1.5' }` }></span>
                  <span className={ `block absolute  h-0.5 w-4 dark:text-white bg-current   transform transition duration-500 ease-in-out ${ isOpen && 'opacity-0' }` }></span>
                  <span className={ `block absolute  h-0.5 w-5 dark:text-white bg-current transform  transition duration-500 ease-in-out ${ isOpen ? '-rotate-45' : 'translate-y-1.5' }` }></span>
                </div>
              </button>
            </div>
            <div className={ `${ isOpen ? 'flex flex-col' : 'hidden' } lg:flex-1 justify-between items-center w-full lg:flex lg:w-auto lg:order-1` }>
              <ul className="flex flex-col mt-4 font-medium w-full lg:w-auto lg:flex-row lg:space-x-8 lg:mt-0">
                <li>
                  <NavLink
                    to="/"
                    className="block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-200 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    onClick={ () => setIsOpen(false) }
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/marketplace"
                    className="block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-200 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    onClick={ () => setIsOpen(false) }
                  >
                    Marketplace
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="about"
                    className="block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-200 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    onClick={ () => setIsOpen(false) }
                  >
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/concept"
                    className="block py-2 pr-4 pl-3 text-gray-900 border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-200 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    onClick={ () => setIsOpen(false) }
                  >
                    Concept
                  </NavLink>
                </li>
              </ul>
              {
                isConnected ?
                  <NavLink
                    to="/account"
                    className="w-full flex items-center justify-center px-4 py-2 mb-3 mt-2 text-base font-bold text-center text-white rounded-lg bg-malacite hover:bg-primary-800 lg:hidden"
                    onClick={ () => setIsOpen(false) }
                  >
                    <IoPersonCircleOutline className="text-[18px] mr-2"/> My Account
                  </NavLink> :
                  <NavLink
                    to="/login"
                    className="w-full flex items-center justify-center px-4 py-2 mb-3 mt-2 text-base font-bold text-center text-white rounded-lg bg-malacite hover:bg-primary-800 lg:hidden"
                    onClick={ () => setIsOpen(false) }
                  >
                    <IoLockClosedOutline className="text-[18px] mr-2"/> Sign In
                  </NavLink>
              }
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};
