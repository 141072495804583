import React from 'react';
import { IoChevronForwardOutline, IoTimeOutline } from 'react-icons/io5';

import GroupImg1 from '../../assets/img/clubs/groups/group_1.svg';
import GroupImg2 from '../../assets/img/clubs/groups/group_2.svg';
import GroupImg3 from '../../assets/img/clubs/groups/group_3.svg';
import GroupImg4 from '../../assets/img/clubs/groups/group_4.svg';

export default function SectionThree() {
  return (
    <section className="bg-white py-3 lg:py-14 dark:bg-gradient-to-t dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="container px-4 py-4 mx-auto">
        <h2 className="text-xl font-bold md:text-2xl lg:text-4xl lg:max-w-md lg:m-auto text-center dark:text-white">
          Experience the excitement of sports like never before!
        </h2>
        <div className="grid lg:gap-4 lg:grid-cols-12 mt-10">
          <div className="lg:col-span-5 mb-4">
            <div className="bg-dark2 rounded-lg flex flex-col justify-center p-8 mb-4">
              <img src={ GroupImg1 } alt="Goall" />
              <div className="flex justify-between mt-4">
                <h3 className="text-white lext-lg md:text-2xl">DANTE x MAVERICK Pack</h3>
                <span className="flex bg-indigo items-center justify-between rounded px-2 text-xs text-white">
                  <IoTimeOutline className="mr-1"/> 05:20
                </span>
              </div>
            </div>
            <div className="bg-dark2 rounded-lg flex flex-col justify-center p-8">
              <img src={ GroupImg2 } alt="Goall" />
              <div className="flex flex-col justify-between mt-4">
                <h3 className="text-white lext-lg md:text-2xl">Brazilian Sensation with CRUZ GARCIA</h3>
                <span className="bg-indigo flex justify-center items-center w-16 rounded px-2 py-1 text-xs text-white">
                  <IoTimeOutline className="inline-block mr-1"/> 05:20
                </span>
              </div>
            </div>
          </div>
          <div className="lg:col-span-7 mb-4">
            <div className="bg-indigo rounded-lg flex flex-col justify-center p-8 mb-4">
              <div className="flex flex-col md:flex-row justify-between mt-4">
                <div className="mb-4">
                  <h3 className="text-white lext-xl md:text-4xl md:leading-[54px] md:w-[70%]">
                    Experience the Brilliance of Leo Cruz: Football&apos;s Greatest Talent
                  </h3>
                  <span className="bg-dark2 rounded px-2 py-1 text-xs text-white">
                    <IoTimeOutline className="inline-block mr-1"/> 05:20
                  </span>
                </div>
                <img className="flex-1 md:max-h-96" src={ GroupImg4 } alt="Goall" />
              </div>
            </div>
            <div className="flex gap-4 justify-between">
              <div className="bg-dark2 rounded-lg justify-center py-4 px-8 relative">
                <span className="bg-indigo rounded px-2 py-1 text-xs text-white absolute top-2 right-2">
                  <IoTimeOutline className="inline-block mr-1"/> 05:20
                </span>
                <img src={ GroupImg3 } alt="Goall" />
                <h3 className="text-white text-base sm:lext-lg md:text-2xl mt-5">The Dynamic Duo: PIERCE x HUNTER</h3>
              </div>
              <div className="bg-dark2 rounded-lg justify-center py-4 px-8 relative">
                <span className="bg-indigo rounded px-2 py-1 text-xs text-white absolute top-2 right-2">
                  <IoTimeOutline className="inline-block mr-1"/> 05:20
                </span>
                <img src={ GroupImg3 } alt="Goall" />
                <h3 className="text-white text-base sm:lext-lg md:text-2xl mt-5">The Lethal Partnership: ROSSI x GARCIA</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:col-span-12 text-center">
          <a href="#" className="text-base underline dark:text-white">See All <IoChevronForwardOutline className="inline-block text-xl"/></a>
        </div>
      </div>
    </section>
  );
};
