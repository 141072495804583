import React from 'react';

import Collection from '../Common/Collection';

export default function MarketPlaceComp() {

  return (
    <section className="bg-white py-3 lg:py-14 dark:bg-gradient-to-t dark:from-indigo3 dark:via-indigo2 dark:to-indigo2">
      <div className="container px-4 py-4">
        <Collection/>
      </div>
    </section>
  );
};
