import React from 'react';
import { IoAlertCircle } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';

interface SuccessProps {
  title: string;
  content: string;
  linkText?: string;
  linkTo?: string
}

export default function ErrorMessage({ title , content, linkText, linkTo }: SuccessProps) {
  return (
    <div className="bg-white rounded-lg p-6 md:mx-auto w-full md:max-w-lg">
      <IoAlertCircle className="text-red-600 w-16 h-16 mx-auto mb-2"/>
      <div className="text-center">
        <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">{ title }</h3>
        <p className="text-xl text-gray-600 my-2">{ content }</p>
        {
          linkText && 
          <div className="py-6 text-center">
            <NavLink to={ linkTo || '/' } className="btn btn-primary btn-block">
              { linkText }
            </NavLink>
          </div>
        }
      </div>
    </div>
  );
}
