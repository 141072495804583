import {
  applyPolyfills,
  defineCustomElements as defineProductMgmtExp,
} from '@fevertokens/ft3/loader';
import { useConnectWallet } from '@web3-onboard/react';
import React, { useEffect, useState } from 'react';
import { FaChartPie, FaClock, FaHeart, FaInfoCircle, FaShieldAlt } from 'react-icons/fa';

// import { MarketPlaceAPI } from '../../app/api/endpoints/Marketplace';
import Bg from '../../assets/img/bg/green_grad.png';
import Logo from '../../assets/img/logo.svg';
import Corner from '../../assets/img/vector/corner.svg';
import Puzzle from '../../assets/img/vector/puzzle.svg';
import { Collection } from '../../models/Collection';
// import { handleError } from '../../helpers/Utils';
import { Item } from '../../models/Item';
import { useTheme } from '../../providers/ThemeProvider';
import Empty from '../Common/Empty';
import GoallIcon from '../Common/GoallIcon';
import ShowDate from '../Common/ShowDate';
import CardPlaceholder from '../Common/Skeleton/CardPlaceholder';
import { Button } from '../Common/ui';

interface HeroProps {
  loading?: boolean;
  collection: Collection | undefined
  item: Item | undefined
}

export default function Hero({ loading, collection, item }: HeroProps) {
  const { theme } = useTheme();
  const [{ wallet, connecting }, connect] = useConnectWallet();
  const [players, setPlayers] = useState({
    playerOne: {
      name: '',
      percent: 0
    },
    playerTwo: {
      name: '',
      percent: 0
    }
  });

  useEffect(() => {
    applyPolyfills().then(() => {
      defineProductMgmtExp(window);
    });
  }, []);

  useEffect(() => {
    if(item && Array.isArray(item?.itemProperties)) {
      const properties = item?.itemProperties;
      let playerOne = '';
      let playerTwo = '';
      for (const prop of properties) {
        if(Object.prototype.hasOwnProperty.call(prop, 'propertyName')) {
          if (prop.propertyName === 'player_one') {
            playerOne = prop.value;
          }
          if (prop.propertyName === 'player_two') {
            playerTwo = prop.value;
          }
        }
      };
      setPlayers({
        playerOne: {
          name: playerOne,
          percent: 0
        },
        playerTwo: {
          name: playerTwo,
          percent: 0
        }
      });
    }
  }, [ item ]);

  // const buy = () => {
  //   if(wallet) {
  //     reedemNft(wallet?.accounts[0]?.address);
  //   } else {
  //     connect().then((res) => {
  //       const [ { accounts } ] = res;
  //       reedemNft( accounts[0]?.address);
  //     });
  //   }
  // };

  // const reedemNft = async (address: string) => {
  //   const { result } = await MarketPlaceAPI.redeemItemTestnet({
  //     collectionId: item?.collectionId,
  //     signingAddress: address,
  //     tokenId: item?.tokenId,
  //     tokenAmount: 1,
  //   }, (error) => {
  //     handleError(error);
  //   });

  //   if (result) {
  //     if (result.status) {
  //       if(result.item.status) {
  //         // proced transaction here with metamask
  //       }
  //     }
  //   }
  // };


  return (
    <section className="bg-white py-4 lg:py-24 relative bg-left-top bg-no-repeat dark:bg-gradient-to-br dark:from-indigo2 dark:via-indigo2 dark:to-indigo3"
      style={{ backgroundImage: theme === 'light' ? `url(${Bg})` : '' }}
    >
      <div className="container px-4">
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-12 lg:gap-6 mt-10">
          <div className="lg:col-span-5">
            {
              loading ? <CardPlaceholder lines={ 3 }/> :
                item && item.visibility ? (
                  <div className="lg:max-w-sm mx-auto sm:mx-0 rounded-xl overflow-hidden shadow-xl px-6 py-4 bg-white relative">
                    <img src={ Corner } className="absolute -top-[2px] -left-[2px] z-0" />
                    <div className="inline-flex items-center justify-center w-full relative">
                      <hr className="w-full h-[4px] my-8 bg-malacite border-0"/>
                      <span className="absolute px-3 font-bold sm:text-2xl text-magenta bg-white -translate-x-1/2 left-1/2 uppercase">
                        { item?.itemName }
                      </span>
                    </div>
                    <div className="lg:w-[336px] h-[300px] rounded-lg object-contain overflow-hidden">
                      <img src={ item?.itemImage } alt={ item?.itemName } />
                    </div>
                    <div className="flex items-center justify-around">
                      <p className="text-lg uppercase truncate max-w-[120px] mx-auto text-magenta text-center mt-2">
                        { players?.playerOne?.name }
                      </p>
                      <p className="text-lg uppercase truncate max-w-[120px] mx-auto text-magenta text-center mt-2">
                        { players?.playerTwo?.name }
                      </p>
                    </div>
                    <div className="flex gap-4 items-start px-6 py-4 mt-4 rounded-t-xl bg-magenta">
                      <div>
                        <img src={ Puzzle } alt="Info" className="w-14" />
                      </div>
                      <div>
                        <h4 className="font-bold text-white mb-1">Pack 54-2023</h4>
                        <p className="text-sm font-sf-pro-text font-thin leading-4 text-white">
                        #{ item?.tokenId } <br />
                          { collection?.club?.name } <br />
                        www.goall.io edition - copyright
                        </p>
                      </div>
                    </div>
                    <div className="inline-flex items-center justify-center w-full relative">
                      <hr className="w-full h-2 my-8 bg-black border-0"/>
                      <span className="absolute px-3 font-bold sm:text-2xl text-magenta bg-white -translate-x-1/2 left-1/2">
                        <img src={ Logo } alt="GOALL" className="h-4" />
                      </span>
                    </div>
                    <img src={ Corner } className="absolute -bottom-[2px] -right-[2px] rotate-180 z-0" />
                  </div>
                ) : <Empty text={ `No player Found for ${collection?.collectionName}` }/>
            }
          </div>
          <div className="lg:col-span-7">
            {
              loading ? <CardPlaceholder repeat={ 2 } withImage={ false } lines={ 6 } widthClass="w-full" classes="mb-6" /> : 
                <>
                  {
                    item && item.visibility ? (
                      <>
                        <h2 className="uppercase text-xl md:text-4xl font-bold mb-2 mt-6 lg:mt-0 dark:text-white">{ item?.itemName }</h2>
                        <div className="flex gap-3">
                          <img src={ collection?.club?.logo } alt={ collection?.club?.name } className="w-10"/>
                          <div>
                            <p className="dark:text-white">
                              <span className="text-gray-400">Club:</span> @{ collection?.club?.slug }
                            </p>
                            <p className="dark:text-white">
                              <span className="text-gray-400">Players:</span> <span className="capitalize">{ players?.playerOne?.name }, { players?.playerTwo?.name }</span>
                            </p>
                          </div>
                        </div>
                        <div className="px-6 py-5 border-2 border-gray-500 rounded-lg mt-6">
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div className="sm:col-span-1 p-6 w-full bg-indigo rounded-lg text-white">
                              <h4 className="text-xl font-bold mb-2">Price</h4>
                              <div className="flex items-start gap-3">
                                <GoallIcon className="h-5 mt-2 fill-white"/>
                                <div className="flex flex-col">
                                  <span className="text-xl md:text-2xl font-bold">${ item?.itemPrice }/NFT</span>
                                  <span className="text-sm text-gray-400">260/{ item?.maxSupply } left</span>
                                </div>
                              </div>
                            </div>
                            <div className="sm:col-span-1 p-6 w-full bg-indigo rounded-lg text-white">
                              <h4 className="text-xl font-bold mb-2">Highest Collector</h4>
                              <div className="flex items-start gap-3">
                                <GoallIcon className="h-5 mt-2 fill-white"/>
                                <div className="flex flex-col">
                                  <span className="text-xl md:text-2xl font-bold">100 NFTs</span>
                                  <span className="text-sm text-gray-400">by @marcoss</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-4 max-w-md mx-auto">
                            <div className="w-full bg-gray-200 rounded-full h-2 dark:bg-white">
                              <div className="bg-scarlet h-2 rounded-full" style={{ width: '48.33%' }}></div>
                            </div>
                            <p className="text-center text-lg mt-2 dark:text-white">
                              <span className="font-bold">290</span> NFTs remaining out of <span className="font-bold">{ item?.maxSupply }</span>
                            </p>
                          </div>
                          <div className="mt-6 flex items-center gap-4">
                            { /* <Button
                                className="btn btn-primary btn-block"
                                onClick={ buy }
                              >
                                Buy now
                              </Button> */ }
                            {
                              collection?.contractAddress ?
                                wallet ? (
                                  <>
                                    {
                                      // @ts-ignore
                                      (<ft3-pay
                                        id="pay-button"
                                        collection-id={ item?.collectionId }
                                        token-id={ item?.tokenId }
                                        text="Buy now"
                                        bg-color="#0bd563"
                                        text-color="#fff"
                                        network="sandbox"
                                      />)
                                    }
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-primary btn-block p-6 mt-3"
                                    disabled={ connecting } onClick={ () => connect() }
                                  >
                                    { connecting ? 'connecting' : 'Connect wallet' }
                                  </button>
                                )
                                :
                                <button
                                  className="flex flex-col btn btn-primary btn-block p-3 mt-3 disabled:cursor-not-allowed disabled:bg-opacity-50"
                                  disabled
                                >
                                  <span>Buy Now</span>
                                  <span className="text-xs font-normal text-red-700">collection not deployed</span>
                                </button>
                            }
                            <Button className="btn btn-light p-6 mb-0"><FaHeart className="text-[24px] fill-gray-400"/></Button>
                          </div>
                        </div>
                        <div className="flex flex-col sm:flex-row gap-4 px-6 py-5 border-2 border-gray-500 rounded-lg mt-6">
                          <div className="w-full">
                            <div className="flex items-center gap-3 mb-4">
                              <FaShieldAlt className="text-lg dark:fill-white"/>
                              <p className="font-sf-pro-text dark:text-white">{ collection?.club?.name }</p>
                            </div>
                            <div className="flex items-center gap-3 sm:mb-4 mb-0">
                              <FaChartPie className="text-lg dark:fill-white"/>
                              <p className="font-sf-pro-text dark:text-white">50% <span className="capitalize">{ players?.playerOne?.name }</span>, 50% <span className="capitalize">{ players?.playerTwo?.name }</span></p>
                            </div>
                          </div>
                          <div className="hidden sm:flex w-1 h-16 bg-black dark:bg-white"/>
                          <div className="w-full">
                            <div className="flex items-center gap-3 mb-4">
                              <FaClock className="text-lg dark:fill-white"/>
                              <p className="font-sf-pro-text dark:text-white">
                                <ShowDate date={ item?.createdAt as Date } format="DD/MM/YYYY H:m" />
                              </p>
                            </div>
                            <div className="flex items-center gap-3 sm:mb-4 mb-0">
                              <FaInfoCircle className="text-lg dark:fill-white"/>
                              <p className="font-sf-pro-text dark:text-white">Learn more</p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : <Empty text={ `No player Found for ${collection?.collectionName}` }/>
                  }
                </>
            }
          </div>
        </div>
      </div>
    </section>
  );
}
