import React from 'react';

import HotBids from '../../components/Common/HotBids';
import LiveAuction from '../../components/Common/LiveAuction';
import FutureSection from '../../components/Home/FutureSection';
import Hero from '../../components/Home/Hero';
import SectionBlog from '../../components/Home/SectionBlog';
import SectionThree from '../../components/Home/SectionThree';
import SectionTwo from '../../components/Home/SectionTwo';

export default function Home() {
  return (
    <>
      <Hero/>
      <SectionTwo/>
      <LiveAuction/>
      <HotBids/>
      <SectionThree/>
      <SectionBlog/>
      <FutureSection/>
    </>
  );
}
