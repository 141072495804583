import React, { FormEvent, useEffect, useState } from 'react';
import { IoEyeSharp } from 'react-icons/io5';
import { IoEyeOffSharp } from 'react-icons/io5';
import { NavLink, useNavigate } from 'react-router-dom';

import { AuthAPI } from '../../app/api/endpoints/Auth';
import Alert from '../../components/Common/ui/Alert';
import Button from '../../components/Common/ui/Button';
import Input from '../../components/Common/ui/Input';
import { format422Error, handleError } from '../../helpers/Utils';
import { RegisterRequestType } from '../../models/Auth';
import { useAuth } from '../../providers/auth/AuthProvider';
import validateInput from '../../validators/register';

export default function Register() {
  const navigate = useNavigate();
  const { isConnected } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [registerSuccess, setRegisterSuccess] = useState<boolean>(false);
  const [inputs, setInputs] = useState<RegisterRequestType>({
    name: '',
    username: '',
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState<RegisterRequestType>({} as RegisterRequestType);
  const [error, setError] = useState<string | undefined>(undefined);
  const [errorEmail, setErrorEmail] = useState<string | undefined>(undefined);
  const [successEmail, setSuccessEmail] = useState<string | undefined>(undefined);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onInputsChange = (e: FormEvent<HTMLInputElement>) => {
    setInputs({ ...inputs, [e.currentTarget.name]: e.currentTarget.value });
    setErrors({
      ...errors,
      [e.currentTarget.name]: undefined
    });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors({} as RegisterRequestType);
    setError(undefined);
    if (isValid()) {
      setLoading(true);
      const { result } = await AuthAPI.Register.post(inputs, (error) => {
        if (error && error.response && error.response?.status == 422) {
          // @ts-ignore
          setErrors(format422Error(error?.response?.data?.errors));
        } else {
          setError(handleError(error));
        }
        setLoading(false);
      });
  
      if (result) {
        if (result.status) {
          setRegisterSuccess(true);
        } else {
          setError(handleError(result.code));
        }
        setLoading(false);
      }
    }
  };

  const isValid = () => {
    const { errors, isValid } = validateInput(inputs);
    if (!isValid) {
      setErrors(errors);
    }
    return isValid;
  };

  const resendActivationLink = async () => {
    setErrorEmail(undefined);
    setSuccessEmail(undefined);
    if (inputs.email) {
      setLoading(true);
      const { result } = await AuthAPI.EmailVerification.resendLink(inputs.email, (error) => {
        setErrorEmail(handleError(error));
        setLoading(false);
      });
  
      if (result) {
        if (result.status) {
          setSuccessEmail('The verification link has been sent successfully.');
        } else {
          setErrorEmail(handleError(result.code));
        }
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    isConnected && navigate('/account');
  }, [ isConnected ]);
  
  return (
    <section className="h-full lg:h-screen w-full flex pt-16 dark:bg-gradient-to-br dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="px-10 pb-20 pt-10 hidden lg:flex items-end justify-end lg:w-[50%] bg-magenta">
        <div className="w-full max-w-lg">
          <h2 className="mb-8 text-6xl font-bold text-white">
            Unlock the Future of Football.
          </h2>
          <p className="text-lg text-white">
            Experience a new dimension of collecting with unique collectibles featuring your favorite football players. Login now to join the revolution of football.
          </p>
        </div>
      </div>
      <div className="p-4 lg:p-10 w-full lg:w-[50%] flex flex-col items-center justify-center overflow-y-auto">
        {
          registerSuccess ? (
            <div className="w-full max-w-md text-center">
              <h2 className="text-xl md:text-3xl font-bold lg:text-4xl capitalize mb-6 dark:text-white">Email Verification</h2>
              <p className="text-sm dark:text-white">
                Thank you for signing up to our NFT marketplace. Please verify your email by clicking on the link we&apos;ve sent to your inbox. Once you&apos;ve confirmed your email, you&apos;ll be able to start browsing and buying unique, one-of-a-kind digital collectibles featuring your favorite football players and moments.
              </p>
              <Button
                type="submit"
                className="btn btn-primary btn-block my-6"
                loading={ loading }
                spinnerclass="text-white"
                onClick={ resendActivationLink }
              >
                resend email
              </Button>
              <a className="block text-center text-sm underline dark:text-white mb-6" href="mailto:contact@goall.io">Contact Support</a>
              { successEmail &&
                <Alert variant="success">{ successEmail }</Alert>
              }
              { errorEmail &&
                <Alert variant="danger">{ errorEmail }</Alert>
              }
            </div>
          ) : (
            <div className="w-full max-w-md">
              <h2 className="text-xl md:text-3xl font-bold lg:text-4xl capitalize mb-6 dark:text-white">Sign up</h2>
              { error &&
                <Alert variant="danger">{ error }</Alert>
              }
              <form onSubmit={ (e) => onSubmit(e) }>
                <Input
                  type="text"
                  name="name"
                  label="Name"
                  placeholder="Enter your full name..."
                  required
                  value={ inputs.name }
                  onChange={ (e) =>  onInputsChange(e) }
                  error={ errors && errors.name }
                />
                <Input
                  type="text"
                  name="username"
                  label="Username"
                  placeholder="Enter your username..."
                  required
                  value={ inputs.username }
                  onChange={ (e) =>  onInputsChange(e) }
                  error={ errors && errors.username }
                  hasPopover
                  popoverContent={ <div
                    className="text-sm text-gray-700 max-w-xs bg-gray-100 p-3 rounded">
                    Username should only contain:
                    <ul className="pl-4 list-disc ">
                      <li>Lowercase letters (a-z)</li>
                      <li>Numbers (0-9)</li>
                    </ul>
                  </div>
                  }
                />
                <Input
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="Enter your Email..."
                  required
                  value={ inputs.email }
                  onChange={ (e) =>  onInputsChange(e) }
                  error={ errors && errors.email }
                />
                <div className="relative">
                  <Input
                    type={ showPassword ? 'text' : 'password' }
                    name="password"
                    label="Password"
                    placeholder="Enter your Password..."
                    required
                    value={ inputs.password }
                    onChange={ (e) =>  onInputsChange(e) }
                    error={ errors && errors.password }
                    hasPopover
                    popoverContent={ <div
                      className="text-sm text-gray-700 max-w-xs bg-gray-100 p-3 rounded">
                      Passwords should contain:
                      <ul className="pl-4 list-disc ">
                        <li>Uppercase letters (A-Z)</li>
                        <li>Lowercase letters (a-z)</li>
                        <li> Numbers (0-9)</li>
                        <li>Symbols (!%@#$&*)</li>
                      </ul>
                    </div>
                    }
                  />
                  <div
                    className="cursor-pointer absolute right-4 top-4"
                    onClick={ () => setShowPassword(!showPassword) }
                  >
                    { showPassword ? (
                      <IoEyeOffSharp className="h-6 w-6 font-extralight" />
                    ) : (
                      <IoEyeSharp className="h-6 w-6 font-extralight" />
                    ) }
                  </div>
                </div>
                <Button
                  type="submit" 
                  className="btn btn-primary btn-block"
                  loading={ loading }
                  spinnerclass="text-white"
                >
                  Create personal account
                </Button>
              </form>
              <p className="text-center mt-6 dark:text-white">Already have an account? <NavLink to="/login" className="text-malacite">Login instead</NavLink></p>
            </div>
          )
        }
      </div>
    </section>
  );
}
