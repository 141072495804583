// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// @ts-ignore
import Validator from 'validator';

import { EMAIL_NOT_VALID,REQUIRED } from './messages';

export default function validateInput(email: string) {
  const errors = {
    email: ''
  };

  if (!Validator.isEmail(email)) {
    errors.email = EMAIL_NOT_VALID;
  }

  if (Validator.isEmpty(email)) {
    errors.email = REQUIRED;
  }

  return {
    errors,
    isValid: isEmpty(errors.email)
  };
}
