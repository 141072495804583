import { useConnectWallet } from '@web3-onboard/react';
import React from 'react';

import { confirmAlert } from '../../helpers/Utils';

export default function WalletConnect() {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

  const disconnectWallet = async () => {
    const confirmed = await confirmAlert();
    if (confirmed) {
      disconnect(wallet!);
    }
  };

  return (
    <div>
      {
        wallet ? (
          <button
            className="btn btn-danger btn-block mt-3"
            onClick={ () => disconnectWallet() }
          >
            Disconnect your wallet
          </button>
        ) : (
          <button
            className="btn btn-primary btn-block mt-3"
            disabled={ connecting } onClick={ () => connect() }
          >
            { connecting ? 'connecting' : 'Connect wallet' }
          </button>
        )
      }
    </div>
  );
}
