import 'react-multi-carousel/lib/styles.css';

import React from 'react';
import Carousel from 'react-multi-carousel';

import { Post } from '../../models/Blog';
import BlogCard from '../Cards/BlogCard';
import CardPlaceholder from '../Common/Skeleton/CardPlaceholder';


interface LatestPostsProps {
  posts: Post[],
  loading?: boolean
}
export default function LatestPosts({ posts, loading }: LatestPostsProps) {
  return (
    <>
      { 
        posts && posts.length > 0 &&
        <Carousel
          additionalTransfrom={ 0 }
          arrows
          autoPlay
          autoPlaySpeed={ 3000 }
          draggable
          focusOnSelect={ false }
          infinite
          keyBoardControl
          minimumTouchDrag={ 80 }
          partialVisible
          pauseOnHover
          renderArrowsWhenDisabled={ false }
          renderButtonGroupOutside={ false }
          renderDotsOutside={ false }
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 768
              },
              items: 3,
              partialVisibilityGutter: 70
            },
            mobile: {
              breakpoint: {
                max: 639,
                min: 0
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 768,
                min: 640
              },
              items: 2,
              partialVisibilityGutter: 10
            }
          }}
          rewind={ false }
          rewindWithAnimation={ false }
          rtl={ false }
          shouldResetAutoplay
          showDots={ false }
          slidesToSlide={ 1 }
          swipeable
          itemClass="flex mr-1 sm:mr-4"
        >
          {
            loading ? (
              <CardPlaceholder repeat={ 4 } />
            ) : (
              posts.map(post => (
                <BlogCard key={ post.id } post={ post }/>
              ))
            )
          }
        </Carousel>
      }
    </>
  );
}
