import React from 'react';

import VecImg from '../../assets/img/vector/vector_2.svg';

export default function Hero() {
  return (
    <section className="h-screen lg:mt-16 py-4 lg:py-24 relative bg-gradient-to-r from-indigo3 via-indigo2 to-indigo2">
      <div className="w-full md:max-w-2xl  absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
        <h1 className="mb-4 font-extrabold tracking-tight text-[32px] leading-[60px] xl:text-[60px] xl:leading-[80px] text-white">
          Unleash Your <span className="inline-block relative">Passion <img className="absolute left-0 bottom-0 w-44 lg:w-full" src={ VecImg } /></span> for <br /> The Game on GOALL.
        </h1>
        <a href="#" className="btn btn-primary mt-4">
          Learn More about us
        </a>
      </div> 
    </section>
  );
};
