import React, { FormEvent, useState } from 'react';
import { IoEyeOffSharp, IoEyeSharp } from 'react-icons/io5';

import { UserAPI } from '../../app/api/endpoints/User';
import { format422Error, handleError, successAlert } from '../../helpers/Utils';
import { PasswordRequestType } from '../../models/User';
import { useAuth } from '../../providers/auth/AuthProvider';
import validateInput from '../../validators/password';
import { Alert, Button, Input } from '../Common/ui';

export default function EditPassword() {
  const { resetAuth } = useAuth();
  const [showPassword, setShowPassword] = useState({
    current_password: false,
    password: false,
    password_confirmation: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [validationError, setValidationErrors] = useState<PasswordRequestType>({} as PasswordRequestType);
  const [inputs, setInputs] = useState<PasswordRequestType>({
    current_password: '',
    password: '',
    password_confirmation: '',
  });
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);


  const onInputsChange = (e: FormEvent<HTMLInputElement>) => {
    setInputs({ ...inputs, [e.currentTarget.name]: e.currentTarget.value });
    setValidationErrors({
      ...validationError,
      [e.currentTarget.name]: undefined
    });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setValidationErrors({} as PasswordRequestType);
    setError(undefined);
    setSuccess(undefined);
    if (isValid()) {
      setLoading(true);
      const { result } = await UserAPI.Profile.updatePassword(inputs, (error) => {
        setLoading(false);
        if (error && error.response && error.response?.status == 422) {
          // @ts-ignore
          setErrors(format422Error(error?.response?.data?.errors));
        } else {
          setError(handleError(error));
        }
      });
  
      if (result) {
        if (result.status) {
          resetAuth();
          successAlert('Password updated Successfully, Re-login');
        } else {
          setError(handleError(result.code));
        }
        setLoading(false);
      }
    }
  };

  const isValid = () => {
    const { errors, isValid } = validateInput(inputs);
    if (!isValid) {
      setValidationErrors(errors);
    }
    return isValid;
  };

  return (
    <div className="mt-4">
      { success &&
        <Alert variant="success">{ success }</Alert>
      }
      { error &&
        <Alert variant="danger">{ error }</Alert>
      }
      <form onSubmit={ (e) => onSubmit(e) }>
        <div className="relative">
          <Input
            type={ showPassword.current_password ? 'text' : 'password' }
            name="current_password"
            label="Current Password"
            placeholder="Enter your current Password..."
            required
            value={ inputs.current_password }
            onChange={ (e) =>  onInputsChange(e) }
            error={ validationError && validationError.current_password }
          />
          <div
            className="cursor-pointer absolute right-4 top-4"
            onClick={ () => setShowPassword({ ...showPassword, current_password: !showPassword.current_password }) }
          >
            { showPassword.current_password ? (
              <IoEyeSharp className="h-6 w-6 font-extralight" />
            ) : (
              <IoEyeOffSharp className="h-6 w-6 font-extralight" />
            ) }
          </div>
        </div>
        <div className="relative">
          <Input
            type={ showPassword.password ? 'text' : 'password' }
            name="password"
            label="Password"
            placeholder="Enter your Password..."
            required
            value={ inputs.password }
            onChange={ (e) =>  onInputsChange(e) }
            error={ validationError && validationError.password }
            hasPopover
            popoverContent={ <div
              className="text-sm text-gray-700 max-w-xs bg-gray-100 p-3 rounded">
                      Passwords should contain:
              <ul className="pl-4 list-disc ">
                <li>Uppercase letters (A-Z)</li>
                <li>Lowercase letters (a-z)</li>
                <li> Numbers (0-9)</li>
                <li>Symbols (!@#$&*)</li>
              </ul>
            </div>
            }
          />
          <div
            className="cursor-pointer absolute right-4 top-4"
            onClick={ () => setShowPassword({ ...showPassword, password: !showPassword.password }) }
          >
            { showPassword.password ? (
              <IoEyeSharp className="h-6 w-6 font-extralight" />
            ) : (
              <IoEyeOffSharp className="h-6 w-6 font-extralight" />
            ) }
          </div>
        </div>
        <div className="relative">
          <Input
            type={ showPassword.password_confirmation ? 'text' : 'password' }
            name="password_confirmation"
            label="Password Confirmation"
            placeholder="Confirm your Password..."
            required
            value={ inputs.password_confirmation }
            onChange={ (e) =>  onInputsChange(e) }
            error={ validationError && validationError.password_confirmation }
          />
          <div
            className="cursor-pointer absolute right-4 top-4"
            onClick={ () => setShowPassword({ ...showPassword, password_confirmation: !showPassword.password_confirmation }) }
          >
            { showPassword.password_confirmation ? (
              <IoEyeSharp className="h-6 w-6 font-extralight" />
            ) : (
              <IoEyeOffSharp className="h-6 w-6 font-extralight" />
            ) }
          </div>
        </div>
        <Button
          type="submit"
          className="btn btn-light btn-block mt-4"
          loading={ loading }
          spinnerclass="text-white"
        >
          save changes
        </Button>
      </form>
    </div>
  );
}
