// @ts-ignore
import _ from 'lodash';
// @ts-ignore
import Validator from 'validator';

import { resetPasswordRequestType } from '../models/Auth';
import { CONFIRMED, REQUIRED } from './messages';

export default function validateInput(data: resetPasswordRequestType) {
  const errors = {
    email: '',
    password: '',
    password_confirmation: ''
  };

  if (Validator.isEmpty(data.password)) {
    errors.password = REQUIRED;
  }

  if (data.password_confirmation !== data.password) {
    errors.password_confirmation = CONFIRMED;
  }

  if (Validator.isEmpty(data.password_confirmation)) {
    errors.password_confirmation = REQUIRED;
  }

  return {
    errors,
    isValid: _.values(errors).every(_.isEmpty)
  };
}
