import React, { useState } from 'react';

import { UserAPI } from '../../app/api/endpoints/User';
import { confirmAlert, errorAlert, handleError, successAlert } from '../../helpers/Utils';
import { useAuth } from '../../providers/auth/AuthProvider';
import { Button } from '../Common/ui';


export default function DeleteAccount() {
  const [loading, setLoading] = useState<boolean>(false);
  const { resetAuth } = useAuth();

  const deleteAccount = async () => {
    const confirmed = await confirmAlert();
    if (confirmed) {
      setLoading(true);
      const { result } = await UserAPI.Profile.deleteAccount((error) => {
        setLoading(false);
        errorAlert(handleError(error));
      });
  
      if (result) {
        if (result.status) {
          resetAuth();
          successAlert('Account Successfully deleted');
        } else {
          errorAlert(handleError(result.code));
        }
        setLoading(false);
      }
    }
  };


  return (
    <Button
      className="btn btn-danger btn-block mt-4"
      loading={ loading }
      spinnerclass="text-white"
      onClick={ deleteAccount }
    >
        delete my account
    </Button>
  );
}
