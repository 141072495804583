import React from 'react';
import { IoPlayOutline } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';

import Bg from '../../assets/img/bg/green_grad.png';
import HeroImg from '../../assets/img/home/hero.png';
import VecImg from '../../assets/img/vector/vector_2.svg';
import { useTheme } from '../../providers/ThemeProvider';

export default function Hero() {
  const { theme } = useTheme();
  return (
    <section className="bg-white lg:h-screen py-4 lg:py-24 relative bg-left-top bg-no-repeat dark:bg-gradient-to-tl dark:from-indigo2 dark:via-indigo2 dark:to-indigo3"
      style={{ backgroundImage: theme === 'light' ? `url(${Bg})` : '' }}
    >
      <div className="container px-4 mx-auto">
        <div className="grid lg:gap-8 xl:gap-0 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-6 pt-14">
            <h1 className="max-w-2xl mb-4 font-extrabold tracking-tight text-[44px] leading-[60px] xl:text-[60px] xl:leading-[80px] dark:text-white">
              The <span className="inline-block relative">Best Way <img className="absolute left-0 bottom-0 w-44 lg:w-full" src={ VecImg } /></span> to <br/> Support the <br/> Clubs you love.
            </h1>
            <p className="max-w-2xl mb-6 font-sf-pro-text font-light text-dark2 lg:mb-8 text-base md:text-lg lg:text-xl dark:text-gray-400">
              Discover the Most Premium, Unique and Exclusive NFT <br/> Players Collections.
            </p>
            <NavLink to="/marketplace" className="w-[150px] sm:w-[170px] btn btn-primary mr-3">
              Explore
            </NavLink>

            <a className="mt-10 md:mt-14 flex">
              <div className="w-6 h-6 sm:w-8 sm:h-8 bg-black rounded-full mr-2 flex justify-center items-center">
                <IoPlayOutline className="text-white text-base sm:text-xl"/>
              </div>
              <p className="font-sf-pro-text font-light text-dark2 text-base lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                Learn more about GOALL
              </p>
            </a>
          </div>
          <div className="mt-16 flex lg:mt-0 lg:col-span-6">
            <img className="mx-auto rounded-xl lg:hidden" src={ HeroImg } alt="Goall" />
          </div>                
        </div>
      </div>
      <img className="hidden rounded-bl-3xl lg:flex lg:absolute lg:top-0 lg:right-0 lg:h-screen" src={ HeroImg } alt="Goall" />
    </section>
  );
};
