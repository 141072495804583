import React from 'react';
import { IoChevronForwardOutline } from 'react-icons/io5';

import Player1 from '../../assets/img/marketplace/player1.png';
import Player2 from '../../assets/img/marketplace/player2.png';
import { Button } from '../Common/ui';

export default function IncludedPlayers() {
  return (
    <section className="bg-white py-3 md:py-8 dark:bg-gradient-to-tr dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="container px-4 py-4 mx-auto">
        <h2 className="text-xl md:text-2xl font-bold lg:text-4xl dark:text-white">
          Included Players
        </h2>
        <div className="grid md:gap-6 md:grid-cols-12 mt-10">
          <div className="md:col-span-6 mb-4">
            <div>
              <img src={ Player1 } alt="Player 1" className="w-full rounded-t-lg" />
              <div className="p-8 bg-dark2 rounded-b-lg">
                <h4 className="text-xl font-bold mb-4 text-malacite">
                  Jaxon Reed <span className="text-xs font-thin ml-4">50% NFT Share</span>
                </h4>
                <ul>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Age:</span> 24
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Height:</span> 1,78 m
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Citizenship:</span> France
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Position:</span> Attack, Centre-Forward
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Foot:</span> Right
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Player Agent:</span> Relatives
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Current Club:</span> Phoenix FC
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Joined:</span> Jul 1, 2018
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Contract Expires:</span> Jun 30, 2025
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Date Of Last Contract Extension:</span> May 21, 2022
                  </li>
                </ul>
                <div className="mt-4 text-right">
                  <a href="#" className="text-base underline text-white">Learn more <IoChevronForwardOutline className="inline-block text-xl"/></a>
                </div>
              </div>
            </div>
          </div>
          <div className="md:col-span-6 mb-4">
            <div>
              <img src={ Player2 } alt="Player 2" className="w-full rounded-t-lg" />
              <div className="p-8 bg-dark2 rounded-b-lg">
                <h4 className="text-xl font-bold mb-4 text-malacite">
                  Leo Cruz <span className="text-xs font-thin ml-4">50% NFT Share</span>
                </h4>
                <ul>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Age:</span> 35
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Height:</span> 1,70 m
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Citizenship:</span> Argentina, Spain
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Position:</span> Attack, Right Winger
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Foot:</span> Left
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Player Agent:</span> Relatives
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Current Club:</span> Phoenix FC
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Joined:</span> Aug 10, 2021
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Contract Expires:</span> Jun 30, 2023
                  </li>
                  <li className="text-base text-white font-sf-pro-text font-thin">
                    <span className="font-bold">Date Of Last Contract Extension:</span> Option for a further year
                  </li>
                </ul>
                <div className="mt-4 text-right">
                  <a href="#" className="text-base underline text-white">Learn more <IoChevronForwardOutline className="inline-block text-xl"/></a>
                </div>
              </div>
            </div>
          </div>
          <div className="md:col-span-12 text-center">
            <Button className="btn btn-primary px-4 sm:px-16">Buy Package Now</Button>
          </div>
        </div>
      </div>
    </section>
  );
}
