import React, { useEffect, useState } from 'react';
import { IoChevronForwardOutline } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';

import { BlogAPI } from '../../app/api/endpoints/Blog';
import { errorAlert, handleError } from '../../helpers/Utils';
import { Post } from '../../models/Blog';
import BlogCard from '../Cards/BlogCard';
import Empty from '../Common/Empty';
import CardPlaceholder from '../Common/Skeleton/CardPlaceholder';

export default function SectionBlog() {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = async () => {
    setLoading(true);
    const { result } = await BlogAPI.getLatestPosts(3, (error) => {
      setLoading(false);
      errorAlert(handleError(error));
    });

    if (result) {
      setLoading(false);
      if (result.status) {
        setPosts(result.latest_posts);
      }
    }
  };
  
  return (
    <section className="bg-white py-3 lg:py-8 dark:bg-gradient-to-b dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="container px-4 py-4 mx-auto">
        <div className="flex items-center justify-between mb-6 md:mb-10">
          <h2 className="text-xl font-bold md:text-2xl lg:text-4xl dark:text-white">
            News
          </h2>
          <NavLink to="/concept" className="text-base underline dark:text-white">
            See All <IoChevronForwardOutline className="inline-block text-xl"/>
          </NavLink>
        </div>
        <div className="grid md:gap-4 md:grid-cols-6 lg:gap-8 lg:grid-cols-12">
          {
            loading ? (
              <CardPlaceholder repeat={ 4 } classes="md:col-span-3 lg:col-span-4 mb-4"/>
            ) : (
              posts && posts.length > 0 ? (
                posts.map(post => (
                  <div key={ post.id } className="md:col-span-3 lg:col-span-4 flex flex-col mb-4">
                    <BlogCard post={ post }/>
                  </div>
                ))
              ) : (
                <Empty/>
              )
            )
          }
        </div>
      </div>
    </section>
  );
};
