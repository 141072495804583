import React from 'react';

import Collection from '../Common/Collection';

export default function Gallery() {

  return (
    <div className="sm:ml-9 sm:mr-9 md:mr-14 md:ml-14 mt-6">
      <h3 className="text-2xl md:text-4xl font-bold mb-6 dark:text-white">Collection</h3>
      <Collection/>
    </div>
  );
}
