import React, { useEffect, useState } from 'react';
import { IoCamera } from 'react-icons/io5';

import { UserAPI } from '../../app/api/endpoints/User';
import { errorAlert, format422Error, handleError, successAlert } from '../../helpers/Utils';
import { useAuth } from '../../providers/auth/AuthProvider';

export default function UpdateCoverImage() {
  const { updateProfile } = useAuth();
  const [currentFile, setCurrentFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  
  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const selectedFiles = files as FileList;
    setCurrentFile(selectedFiles?.[0]);
    setLoading(false);
  };

  useEffect(() => {
    upload(currentFile);
  }, [ currentFile ]);

  const upload = async (currentFile: File | undefined) => {
    if (currentFile) {
      setLoading(false);
      if(currentFile.size <= 4e+6) {
        if(['image/png', 'image/jpg', 'image/jpeg'].includes(currentFile.type)) {
          const formData = new FormData();

          formData.append('cover_image', currentFile);

          setLoading(true);
          const { result } = await UserAPI.Profile.updateCoverImage(formData, (error) => {
            setLoading(false);
            if (error && error.response && error.response?.status == 422) {
              // @ts-ignore
              errorAlert(format422Error(error?.response?.data?.errors));
            } else {
              errorAlert(handleError(error));
            }
          });
  
          if (result) {
            if (result.status) {
              updateProfile(result.profile);
              setCurrentFile(undefined);
              successAlert('Cover photo updated Successfully');
            } else {
              errorAlert(handleError(result.code));
            }
            setLoading(false);
          }
        } else {
          errorAlert('Cover image type not allowed.');
          setCurrentFile(undefined);
        }
      } else {
        errorAlert('Cover image sould be less then 4mb.');
        setCurrentFile(undefined);
      }
    }
  };

  return (
    <label htmlFor="cover-image" className="flex items-center px-2 py-1 rounded-lg cursor-pointer bg-gray-200 absolute right-2 top-2">
      {
        !loading ?
          <>
            <IoCamera className="w-6 h-6 md:mr-2"/> <span className="hidden md:block">Update cover photo</span>
            <input id="cover-image" type="file" className="hidden" onChange={ onSelectFile } />
          </> :
          <>
            Uploading ...
          </>
      }
    </label>
  );
}
