import { AxiosError } from 'axios';
import React, { ReactNode, useContext, useEffect } from 'react';
import { useState } from 'react';
import { createContext } from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../app/api';
import { AuthAPI } from '../../app/api/endpoints/Auth';
import { UserAPI } from '../../app/api/endpoints/User';
import { storeToken, verifyToken } from '../../helpers/HandleToken';
import { destroyToken } from '../../helpers/HandleToken';
import { errorAlert, handleError } from '../../helpers/Utils';
import { Token } from '../../models/Auth';
import { User } from '../../models/User';


interface AuthProviderProps {
  children?: ReactNode;
}

export const AuthContext = createContext<{
  profile: User | undefined,
  isConnected: boolean,
  loading: boolean,
  checkingConnexion: boolean,
  error: string | undefined,
  setError: (error: string | undefined) => void,
  login: (email: string, password: string) => void,
  updateProfile: (user: User | undefined) => void,
  postLogin: (token: Token) => void,
  logout: () => void,
  resetAuth: () => void,
  twoFactorLogin: (twoFactorCode: string | null, recoveryCode: string | null) => Promise<void>,
  updateTwoFactorStatus: (status: boolean) => void,
  twoFactorStatus: boolean,
  challengeToken: string,
    }>({
      profile: undefined,
      isConnected: false,
      loading: false,
      checkingConnexion: false,
      error: undefined,
      setError: () => null,
      login: () => null,
      updateProfile: () => null,
      resetAuth: () => null,
      postLogin: () => null,
      logout: () => null,
      twoFactorLogin: () => Promise.resolve(),
      updateTwoFactorStatus: () => null,
      twoFactorStatus: false,
      challengeToken: '',
    });

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const auth = useAuthProvider();
  return (
    <AuthContext.Provider value={ auth }>
      { children }
    </AuthContext.Provider>
  );
};

const useAuthProvider = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState<User | undefined>(undefined);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [checkingConnexion, setCheckingConnexion] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [twoFactorStatus, setTwoFactorStatus] = useState<boolean>(false);
  const [challengeToken, setChallengeToken] = useState<string>('');

  const postLogin = async (token: Token) => {
    API.defaults.headers.common['Authorization'] = 'Bearer ' + token.token;
    const { result } = await UserAPI.Profile.get(() => null);
    if (result && result.status) {
      setProfile(result.profile);
      setIsConnected(true);
      storeToken(token);
      navigate('/account');
    }
  };

  const login = async (email: string, password: string) => {
    setLoading(true);
    const { result } = await AuthAPI.Login.post({
      email: email,
      password: password
    }, (err) => {
      const error = err as AxiosError;
      setError(error.message);
      setLoading(false);
    });

    if (result) {
      if (result.status) {
        await postLogin(result.token);
      } else {
        if(result.two_factor_required) {
          setChallengeToken(result.challenge_token);
          setTwoFactorStatus(true);
          navigate('/verify-2fa');
        } else {
          setError('Email or password incorrect.');
        }
      }
      setLoading(false);
    }
  };

  const twoFactorLogin = async (twoFactorCode: string | null, recoveryCode: string | null) => {
    const { result } = await AuthAPI.challenge.post(challengeToken, twoFactorCode, recoveryCode, (err) => {
      setError(handleError(err));
    });

    if (result) {
      if (result.status) {
        await postLogin(result.token);
      } else {
        setError(handleError(result.code));
      }
    }
  };

  const logout = async () => {
    const { result } = await AuthAPI.logout.post((err) => {
      errorAlert(handleError(err));
    });

    if (result && result.status) {
      resetAuth();
    }
  };

  const resetAuth = () => {
    destroyToken();
    setProfile(undefined);
    setIsConnected(false);
    setTwoFactorStatus(false);
    navigate('/login');
  };

  useEffect(() => {
    const checkAuth = async() => {
      const token = await verifyToken();
      if (token) {
        setCheckingConnexion(true);
        API.defaults.headers.common['Authorization'] = token.token;
        const { result } = await UserAPI.Profile.get(() => null);
        if (result && result.status) {
          setCheckingConnexion(false);
          setProfile(result.profile);
          setIsConnected(true);
          if(result.profile.isTwoFactorEnabled) {
            setTwoFactorStatus(true);
          }
        } else setCheckingConnexion(false);
      }
    };
    checkAuth();
  }, []);

  return {
    profile,
    isConnected,
    loading,
    checkingConnexion,
    error,
    setError,
    login,
    updateProfile: setProfile,
    postLogin,
    logout,
    resetAuth,
    updateTwoFactorStatus: setTwoFactorStatus,
    twoFactorStatus,
    challengeToken,
    twoFactorLogin
  };
};

export const useAuth = () => useContext(AuthContext);
