import React from 'react';
import Select from 'react-select';
import SingleValue from 'react-select';

export type Option = {
  label: string;
  value: string;
}
interface SelectInputProps {
  options: Option[];
  // @ts-ignore
  onChange: (arg: SingleValue<Option>) => void;
  // @ts-ignore
  defaultValue?: SingleValue<Option>;
  name?: string;
  required?: boolean;
  label?: string;
  error?: string 
}

export default function SelectInput(props: SelectInputProps) {
  const { required, label, ...rest } = props;
  return (
    <div className="w-full relative mb-6">
      <Select
        { ...rest }
        // @ts-ignore
        onChange={ (e: SingleValue<Option>) => props.onChange(e) }
        classNames={{
          container: () => 'block pb-2.5 pt-2 h-14 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-dark2 appearance-none dark:text-white dark:border-white focus:outline-none ring-0',
          control: () => '!border-0 !shadow-none hover:!border-transparent hover:!shadow-transparent dark:!bg-transparent dark:!text-white',
          singleValue: () => 'dark:!text-white',
          option: () => '!left-0 dark:!text-grey'
        }}
        id={ props.name }
        options={ props.options }
      />
      <label htmlFor={ props.name } className="absolute text-base text-black font-semibold bg-white dark:bg-indigo2 dark:text-white duration-300 transform z-2 origin-[0] px-2 top-1 scale-75 -translate-y-4 left-2">
        {
          label
        } { required && <span className="text-red-400">*</span> }
      </label>
      {
        props.error &&
        <p className="mt-1 text-sm text-red-600 dark:text-red-400">{ props.error }</p>
      }
    </div>
  );
};
