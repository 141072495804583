import React from 'react';

import ActivityCard from '../Cards/ActivityCard';


export default function LatestActivities() {
  return (
    <section className="bg-white py-3 md:py-8 dark:bg-gradient-to-b dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="container px-4 py-4 mx-auto">
        <h2 className="text-xl md:text-2xl font-bold lg:text-4xl dark:text-white">
          Latest Activity
        </h2>
        <div className="grid md:gap-6 md:grid-cols-12 mt-10">
          <div className="md:col-span-6 mb-4 md:mb-0">
            <ActivityCard/>
          </div>
          <div className="md:col-span-6 mb-4 md:mb-0">
            <ActivityCard/>
          </div>
          <div className="md:col-span-6 mb-4 md:mb-0">
            <ActivityCard/>
          </div>
          <div className="md:col-span-6 mb-4 md:mb-0">
            <ActivityCard/>
          </div>
        </div>
      </div>
    </section>
  );
}
