import React from 'react';
import { FiDownload } from 'react-icons/fi';

export default function PaymentHistory() {

  return (
    <div className="sm:ml-9 sm:mr-9 md:mr-14 md:ml-14 mt-6">
      <h3 className="text-2xl md:text-4xl font-bold mb-6 dark:text-white">Payment History</h3>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-center border-separate border-spacing-x-0 border-spacing-y-2">
          <thead>
            <tr>
              <th className="pr-1"><span className="py-3 px-2 flex rounded-lg bg-grey2 justify-center whitespace-nowrap">Payement ID</span></th>
              <th className="px-1"><span className="py-3 px-2 flex rounded-lg bg-grey2 justify-center whitespace-nowrap">Payement Date</span></th>
              <th className="px-1"><span className="py-3 px-2 flex rounded-lg bg-grey2 justify-center whitespace-nowrap">Payement Method</span></th>
              <th className="px-1"><span className="py-3 px-2 flex rounded-lg bg-grey2 justify-center whitespace-nowrap">Package</span></th>
              <th className="px-1"><span className="py-3 px-2 flex rounded-lg bg-grey2 justify-center whitespace-nowrap">Quantity</span></th>
              <th className="px-1"><span className="py-3 px-2 flex rounded-lg bg-grey2 justify-center whitespace-nowrap">Toatal Amount</span></th>
              <th className="px-1"><span className="py-3 px-2 flex rounded-lg bg-grey2 justify-center whitespace-nowrap">Status</span></th>
              <th className="pl-1"><span className="py-3 px-2 flex rounded-lg bg-violet text-white justify-center whitespace-nowrap">Invoice</span></th>
            </tr>
          </thead>
          <tbody>
            <tr className="">
              <td className="p-3 rounded-tl-lg rounded-bl-lg bg-dark2 text-white">#12345</td>
              <td className="p-3 bg-dark2 text-white">18/01/2023</td>
              <td className="p-3 bg-dark2 text-white">Credit / Debit Card</td>
              <td className="p-3 bg-dark2 text-white">MGOM-KBAR</td>
              <td className="p-3 bg-dark2 text-white">2</td>
              <td className="p-3 bg-dark2 text-white">$202</td>
              <td className="p-3 bg-dark2 text-white">Paid</td>
              <td className="p-3 rounded-br-lg rounded-tr-lg bg-dark2 text-white"><FiDownload className="mx-auto"/></td>
            </tr>
            <tr>
              <td className="p-3 rounded-tl-lg rounded-bl-lg bg-dark2 text-white">#12345</td>
              <td className="p-3 bg-dark2 text-white">18/01/2023</td>
              <td className="p-3 bg-dark2 text-white">Credit / Debit Card</td>
              <td className="p-3 bg-dark2 text-white">MGOM-KBAR</td>
              <td className="p-3 bg-dark2 text-white">2</td>
              <td className="p-3 bg-dark2 text-white">$202</td>
              <td className="p-3 bg-dark2 text-white">Paid</td>
              <td className="p-3 rounded-br-lg rounded-tr-lg bg-dark2 text-white"><FiDownload className="mx-auto"/></td>
            </tr>
            <tr>
              <td className="p-3 rounded-tl-lg rounded-bl-lg bg-dark2 text-white">#12345</td>
              <td className="p-3 bg-dark2 text-white">18/01/2023</td>
              <td className="p-3 bg-dark2 text-white">Credit / Debit Card</td>
              <td className="p-3 bg-dark2 text-white">MGOM-KBAR</td>
              <td className="p-3 bg-dark2 text-white">2</td>
              <td className="p-3 bg-dark2 text-white">$202</td>
              <td className="p-3 bg-dark2 text-white">Paid</td>
              <td className="p-3 rounded-br-lg rounded-tr-lg bg-dark2 text-white"><FiDownload className="mx-auto"/></td>
            </tr>
            <tr>
              <td className="p-3 rounded-tl-lg rounded-bl-lg bg-dark2 text-white">#12345</td>
              <td className="p-3 bg-dark2 text-white">18/01/2023</td>
              <td className="p-3 bg-dark2 text-white">Credit / Debit Card</td>
              <td className="p-3 bg-dark2 text-white">MGOM-KBAR</td>
              <td className="p-3 bg-dark2 text-white">2</td>
              <td className="p-3 bg-dark2 text-white">$202</td>
              <td className="p-3 bg-dark2 text-white">Paid</td>
              <td className="p-3 rounded-br-lg rounded-tr-lg bg-dark2 text-white"><FiDownload className="mx-auto"/></td>
            </tr>
            <tr>
              <td className="p-3 rounded-tl-lg rounded-bl-lg bg-dark2 text-white">#12345</td>
              <td className="p-3 bg-dark2 text-white">18/01/2023</td>
              <td className="p-3 bg-dark2 text-white">Credit / Debit Card</td>
              <td className="p-3 bg-dark2 text-white">MGOM-KBAR</td>
              <td className="p-3 bg-dark2 text-white">2</td>
              <td className="p-3 bg-dark2 text-white">$202</td>
              <td className="p-3 bg-dark2 text-white">Paid</td>
              <td className="p-3 rounded-br-lg rounded-tr-lg bg-dark2 text-white"><FiDownload className="mx-auto"/></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
