import React from 'react';

import Bg from '../assets/img/bg/green_grad.png';
import { useTheme } from '../providers/ThemeProvider';

export default function Terms() {
  const { theme } = useTheme();
  return (
    <section className="bg-white py-4 lg:py-24 relative bg-left-top bg-no-repeat dark:bg-gradient-to-br dark:from-indigo2 dark:via-indigo2 dark:to-indigo3"
      style={{ backgroundImage: theme === 'light' ? `url(${Bg})` : '' }}
    >
      <div className="container px-4 mx-auto">
        <h2 className="font-bold text-3xl lg:text-4xl mt-14 lg:mt-8 mb-8 lg:mb-10 dark:text-white">
          GOALL Terms and Conditions
        </h2>
        <h3 className="font-bold text-xl lg:text-2xl mb-4 mt-8 dark:text-white">Introduction</h3>
        <p className="text-sm sm:text-base dark:text-white">
        This Terms of Service agreement (the &quot;Agreement&quot;) is entered into by and between the user of the website or services offered by GOALL (collectively, the &quot;Services&quot;), and GOALL, a company organized under the laws of [insert jurisdiction]. This Agreement sets forth the terms and conditions under which the Services will be provided to the user.
        </p>
        <h3 className="font-bold text-xl lg:text-2xl mb-4 mt-8 dark:text-white">Use of the Services</h3>
        <p className="text-sm sm:text-base dark:text-white">
        The Services are offered for the user&apos;s personal, non-commercial use only. The user may not use the Services for any illegal purpose or in any manner inconsistent with this Agreement. The user may not use the Services in any manner that could damage, disable, overburden, or impair any GOALL server, or the network(s) connected to any GOALL server, or interfere with any other party&apos;s use and enjoyment of the Services.
        </p>
        <h3 className="font-bold text-xl lg:text-2xl mb-4 mt-8 dark:text-white">Content</h3>
        <p className="text-sm sm:text-base dark:text-white">
        The user acknowledges that all content included on the Services, including but not limited to text, graphics, logos, images, audio, and software, is owned by GOAL or its licensors, and is protected by applicable intellectual property and other laws. The user may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or content obtained from the Services, except as specifically provided in this Agreement.
        </p>
        <h3 className="font-bold text-xl lg:text-2xl mb-4 mt-8 dark:text-white">User Conduct</h3>
        <p className="text-sm sm:text-base dark:text-white">
        The user agrees not to engage in any conduct that, in GOALL&apos;s sole discretion, restricts or inhibits any other user from using or enjoying the Services, or that could damage, disable, or overburden the Services, or the network(s) connected to the Services, or interfere with GOALL&apos;s ability to provide the Services. This includes, but is not limited to, the following: (a) transmitting any content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another&apos;s privacy, hateful, or racially, ethnically, or otherwise objectionable; (b) impersonating any person or entity, or falsely stating or otherwise misrepresenting your affiliation with a person or entity; and (c) forging headers or otherwise manipulating identifiers in order to disguise the origin of any content transmitted through the Services.
        </p>
        <h3 className="font-bold text-xl lg:text-2xl mb-4 mt-8 dark:text-white">User Information</h3>
        <p className="text-sm sm:text-base dark:text-white">
        The user agrees to provide accurate, current, and complete information about the user as may be prompted by the Services&apos; registration process. The user agrees to maintain and update this information as needed to keep it accurate, current, and complete. The user acknowledges that, if any information provided by the user is false, inaccurate, not current, or incomplete, GOALL reserves the right to terminate this Agreement and the user&apos;s use of the Services.
        </p>
        <h3 className="font-bold text-xl lg:text-2xl mb-4 mt-8 dark:text-white">Disclaimer of Warranties</h3>
        <p className="text-sm sm:text-base dark:text-white">
        The Services are provided on an &quot;as is&quot; and &quot;as available&quot; basis. GOALL makes no representations or warranties of any kind, express or implied, as to the operation of the Services or the information, content, or materials included on the Services. The user expressly agrees that the user&apos;s use of the Services is at the user&apos;s sole risk. GOALL does not warrant that the Services will be uninterrupted or error-free, and GOALL will not be liable for any interruptions or errors.
        </p>
        <h3 className="font-bold text-xl lg:text-2xl mb-4 mt-8 dark:text-white">Limitation of Liability</h3>
        <p className="text-sm sm:text-base dark:text-white">
        GOALL shall not be liable for any damages of any kind arising from the use of the Services, including but not limited to direct, indirect, incidental, punitive, and consequential damages, even if GOALL has been advised of the possibility of such damages.
        </p>
      </div>
    </section>
  );
};
