import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import AvatarEditor from 'react-avatar-editor';
import { IoCamera } from 'react-icons/io5';

import { UserAPI } from '../../app/api/endpoints/User';
import { errorAlert, format422Error, handleError, successAlert } from '../../helpers/Utils';
import { useAuth } from '../../providers/auth/AuthProvider';
import { Modal, ModalBody } from '../Common/Modal';
import { Button } from '../Common/ui';

export default function UpdateAvatar() {
  const { updateProfile } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [showUpdateAvatarModal, setShowUpdateAvatarModal] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<File>();
  const editor = useRef(null);

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const selectedFiles = files as FileList;
    setAvatar(selectedFiles?.[0]);
    setLoading(false);
  };

  useEffect(() => {
    if (avatar) {
      if(avatar.size > 4e+6) {
        errorAlert('Cover image sould be less then 4mb.');
        setAvatar(undefined);
      } else {
        if(!['image/png', 'image/jpg', 'image/svg'].includes(avatar.type)) {
          errorAlert('Cover image type not allowed.');
          setAvatar(undefined);
        }
      }
    }
  }, [ avatar ]);

  const onUpload = async () => {
    // @ts-ignore
    const file = editor?.current?.getImageScaledToCanvas().toDataURL();
    const blob = await fetch(file);
    const fileToSave = await blob.blob();
    
    const formData = new FormData();
    formData.append('avatar', fileToSave);

    setLoading(true);
    const { result } = await UserAPI.Profile.updateAvatarImage(formData, (error) => {
      setLoading(false);
      if (error && error.response && error.response?.status == 422) {
        // @ts-ignore
        errorAlert(format422Error(error?.response?.data?.errors));
      } else {
        errorAlert(handleError(error));
      }
    });
  
    if (result) {
      if (result.status) {
        updateProfile(result.profile);
        setAvatar(undefined);
        setShowUpdateAvatarModal(false);
        successAlert('Avatar updated Successfully');
      } else {
        errorAlert(handleError(result.code));
      }
      setLoading(false);
    }
  };

  return (
    <>
      <span
        className="cursor-pointer rounded-full bg-gray-200 p-1 absolute right-0 bottom-0"
        onClick={ () => {
          setShowUpdateAvatarModal(true);
          setAvatar(undefined);
        } }
      >
        <IoCamera className="w-3 h-3 md:w-6 md:h-6"/>
      </span>
      <Modal
        showModal={ showUpdateAvatarModal }
        setShowModal={ setShowUpdateAvatarModal }
      >
        <ModalBody>
          {
            !avatar ? (
              <div className="flex items-center justify-center w-full">
                <label htmlFor="avatar" className="flex flex-col items-center justify-center px-2 w-full py-6 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG or JPG (MAX. 4mb)</p>
                  </div>
                  <input id="avatar" type="file" className="hidden" onChange={ onSelectFile } />
                </label>
              </div>
            ) : (
              <>
                <AvatarEditor
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: 10
                  }}
                  ref={ editor }
                  image={ avatar }
                  width={ 250 }
                  height={ 250 }
                  border={ 50 }
                  color={ [255, 255, 255, 0.6] } // RGBA
                  scale={ 1.2 }
                  rotate={ 0 }
                />
                <div className="flex gap-2 mt-3">
                  <label className="btn btn-light btn-block">
                    <span>Upload another</span>
                    <input id="avatar" type="file" className="hidden" onChange={ onSelectFile } />
                  </label>
                  <Button
                    className="btn btn-primary btn-block"
                    loading={ loading }
                    disabled={ !!avatar }
                    onClick={ onUpload }
                  >
                    Save
                  </Button>
                </div>
              </> 
            )
          }
        </ModalBody>
      </Modal>
    </>
  );
}
