import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Alert, Button, Input } from '../../components/Common/ui/';
import { useAuth } from '../../providers/auth/AuthProvider';

export default function TwoFactorVerification() {
  const { loading, twoFactorLogin, error, setError } = useAuth();
  const [token, setToken] = useState<string>('');

  const onSubmit = async () => {
    setError(undefined);
    if (token !== '' && !isNaN(parseInt(token))) {
      await twoFactorLogin(token, null);
    } else {
      setError('Code should be a valid 6 digits.');
    }
  };

  return (
    <section className="h-full lg:h-screen w-full flex pt-16 dark:bg-gradient-to-br dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="px-10 pb-20 pt-10 hidden lg:flex items-end justify-end lg:w-[50%] bg-magenta">
        <div className="w-full max-w-lg">
          <h2 className="mb-8 text-6xl font-bold text-white">
            Unlock the Future of Football.
          </h2>
          <p className="text-lg text-white">
            Experience a new dimension of collecting with unique collectibles featuring your favorite football players. Login now to join the revolution of football.
          </p>
        </div>
      </div>
      <div className="p-4 lg:p-10 w-full lg:w-[50%] flex flex-col items-center justify-center">
        <div className="w-full max-w-md">
          <h2 className="text-xl md:text-3xl font-bold lg:text-4xl capitalize mb-6 dark:text-white">Verify your account</h2>
          { error &&
            <Alert variant="danger">{ error }</Alert>
          }
          <h4 className="text-base font-bold dark:text-white">
            Authentication code
          </h4>
          <p className="text-sm mb-3 dark:text-white">Enter verification code from your authenticator app</p>
          <Input
            type="text"
            name="token"
            label="Code"
            placeholder="Enter 6 digits code..."
            value={ token }
            onChange={ (e) =>  setToken(e.target.value) }
            required
          />
          <Button
            type="submit"
            className="btn btn-primary btn-block mt-4"
            loading={ loading }
            spinnerclass="text-white"
            onClick={ onSubmit }
          >
            Verify
          </Button>
          <p className="text-center mt-6 dark:text-white">Lost access to your device? <NavLink to="/verify-2fa-recovery" className="text-malacite">Tap here to use a recovery code.</NavLink></p>
        </div>
      </div>
    </section>
  );
}
