import React from 'react';
import { NavLink } from 'react-router-dom';

interface SuccessProps {
  title: string;
  content: string;
  linkText?: string;
  linkTo?: string
}

export default function Success({ title , content, linkText, linkTo }: SuccessProps) {
  return (
    <div className="bg-white rounded-lg p-6 md:mx-auto w-full md:max-w-lg">
      <svg viewBox="0 0 24 24" className="text-green-600 w-16 h-16 mx-auto mb-2">
        <path fill="currentColor"
          d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
        </path>
      </svg>
      <div className="text-center">
        <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">{ title }</h3>
        <p className="text-gray-600 my-2">{ content }</p>
        {
          linkText && 
          <div className="py-6 text-center">
            <NavLink to={ linkTo || '/' } className="btn btn-primary btn-block">
              { linkText }
            </NavLink>
          </div>
        }
      </div>
    </div>
  );
}
