import axios, { AxiosError } from 'axios';

import { API_URL } from '../../config';
import { destroyToken, verifyToken } from '../../helpers/HandleToken';

const API = axios.create({
  baseURL: `${API_URL}api/v1/`,
});

API.interceptors.request.use(async (config) => {
  const token = await verifyToken();
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token.token;
  }
  return config;
},
error => {
  Promise.reject(error);
});

API.interceptors.response.use(undefined, async (error: AxiosError) => {
  if (error.response?.status == 401) {
    await destroyToken();
  }
  return Promise.reject(error);
});

export default API;
