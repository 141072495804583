import React, { FormEvent, useEffect, useState } from 'react';
import { IoEyeOffSharp, IoEyeSharp } from 'react-icons/io5';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AuthAPI } from '../../app/api/endpoints/Auth';
import Success from '../../components/Common/SuccessMessage';
import { Alert, Button, Input } from '../../components/Common/ui';
import { handleError } from '../../helpers/Utils';
import { resetPasswordRequestType } from '../../models/Auth';
import { useAuth } from '../../providers/auth/AuthProvider';
import validateInput from '../../validators/reset_password';

export default function ResetPassword() {
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate();
  const { isConnected } = useAuth();
  const [token , setToken] = useState<string | null>(null);
  const [loading , setLoading] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<resetPasswordRequestType>({} as resetPasswordRequestType);
  const [success , setSuccess] = useState<boolean>(false);
  const [error , setError] = useState<string | undefined>(undefined);
  const [inputs, setInputs] = useState<resetPasswordRequestType>({
    token: '',
    password: '',
    password_confirmation: ''
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
    password_confirmation: false,
  });


  const onInputsChange = (e: FormEvent<HTMLInputElement>) => {
    setInputs({ ...inputs, [e.currentTarget.name]: e.currentTarget.value });
    setValidationError({
      ...validationError,
      [e.currentTarget.name]: undefined
    });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setValidationError({} as resetPasswordRequestType);
    if (isValid()) {
      setLoading(true);
      const { result } = await AuthAPI.ResetPassword.post(inputs, (error) => {
        setLoading(false);
        if (error && error.response && error.response?.status == 422) {
          // @ts-ignore
          setValidationError(format422Error(error?.response?.data?.errors));
        } else {
          setError(handleError(error));
        }
      });
  
      if (result) {
        if (result.status) {
          setSuccess(true);
        } else {
          setError(handleError(result.code));
        }
        setLoading(false);
      }
    }
  };

  const isValid = () => {
    const { errors, isValid } = validateInput(inputs);
    if (!isValid) {
      setValidationError(errors);
    }
    return isValid;
  };

  useEffect(() => {
    setToken(searchParams.get('token'));
  }, [ searchParams ]);

  useEffect(() => {
    if(token){
      setInputs({
        ...inputs,
        token: token
      });
    }
  }, [ token ]);
  
  useEffect(() => {
    isConnected && navigate('/account');
  }, [ isConnected ]);

  return (
    <section className="h-full lg:h-screen w-full flex pt-16 dark:bg-gradient-to-br dark:from-indigo2 dark:via-indigo2 dark:to-indigo3">
      <div className="px-10 pb-20 pt-10 hidden lg:flex items-end justify-end lg:w-[50%] bg-magenta">
        <div className="w-full max-w-lg">
          <h2 className="mb-8 text-6xl font-bold text-white">
            Unlock the Future of Football.
          </h2>
          <p className="text-lg text-white">
            Experience a new dimension of collecting with unique collectibles featuring your favorite football players. Login now to join the revolution of football.
          </p>
        </div>
      </div>
      <div className="p-4 lg:p-10 w-full lg:w-[50%] flex flex-col items-center justify-center">
        {
          success ?
            <Success
              title="Reset Password Done"
              content="Your password has been changed succsfully."
              linkText="Login"
              linkTo="/login"
            />
            : (
              <div className="w-full max-w-md">
                <h2 className="text-xl md:text-3xl font-bold lg:text-4xl capitalize mb-6 dark:text-white">Reset password</h2>
                { error &&
                  <Alert variant="danger">{ error }</Alert>
                }
                <form onSubmit={ (e) => onSubmit(e) }>
                  <div className="relative">
                    <Input
                      type={ showPassword.password ? 'text' : 'password' }
                      name="password"
                      label="Password"
                      placeholder="Enter your Password..."
                      required
                      value={ inputs.password }
                      onChange={ (e) =>  onInputsChange(e) }
                      error={ validationError && validationError.password }
                      hasPopover
                      popoverContent={ <div
                        className="text-sm text-gray-700 max-w-xs bg-gray-100 p-3 rounded">
                        Passwords should contain:
                        <ul className="pl-4 list-disc ">
                          <li>Uppercase letters (A-Z)</li>
                          <li>Lowercase letters (a-z)</li>
                          <li> Numbers (0-9)</li>
                          <li>Symbols (!@#$&*)</li>
                        </ul>
                      </div>
                      }
                    />
                    <div
                      className="cursor-pointer absolute right-4 top-4"
                      onClick={ () => setShowPassword({ ...showPassword, password: !showPassword.password }) }
                    >
                      { showPassword.password ? (
                        <IoEyeSharp className="h-6 w-6 font-extralight" />
                      ) : (
                        <IoEyeOffSharp className="h-6 w-6 font-extralight" />
                      ) }
                    </div>
                  </div>
                  <div className="relative">
                    <Input
                      type={ showPassword.password_confirmation ? 'text' : 'password' }
                      name="password_confirmation"
                      label="Password Confirmation"
                      placeholder="Confirm your Password..."
                      required
                      value={ inputs.password_confirmation }
                      onChange={ (e) =>  onInputsChange(e) }
                      error={ validationError && validationError.password_confirmation }
                    />
                    <div
                      className="cursor-pointer absolute right-4 top-4"
                      onClick={ () => setShowPassword({ ...showPassword, password_confirmation: !showPassword.password_confirmation }) }
                    >
                      { showPassword.password_confirmation ? (
                        <IoEyeOffSharp className="h-6 w-6 font-extralight" />
                      ) : (
                        <IoEyeSharp className="h-6 w-6 font-extralight" />
                      ) }
                    </div>
                  </div>
                  <Button
                    type="submit" 
                    className="btn btn-primary btn-block mt-4"
                    loading={ loading }
                    spinnerclass="text-white"
                  >
                    Save new password
                  </Button>
                </form>
              </div>
            )
        }
      </div>
    </section>
  );
}
