import React, { FormEvent, useEffect, useState } from 'react';

import { formatNumber } from '../helpers/Utils';

export default function ToolDemo() {
  const [estimationInputs, setEstimationInputs] = useState({
    playerOne: '',
    playerTwo: '',
    subscription: '',
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [amountTargeted, setAmountTargeted] = useState(0);

  const onEstimationChange = (e: FormEvent<HTMLInputElement>) => {
    setEstimationInputs({ ...estimationInputs, [e.currentTarget.name]: e.currentTarget.value });
  };

  useEffect(() => {
    if(parseFloat(estimationInputs.playerOne)>0 && parseFloat(estimationInputs.playerTwo)>0){
      setTotalPrice(parseFloat(estimationInputs.playerOne)+parseFloat(estimationInputs.playerTwo));
    } else {
      setTotalPrice(0);
    }
  }, [estimationInputs.playerOne, estimationInputs.playerTwo]);

  useEffect(() => {
    if(parseFloat(estimationInputs.subscription)>0 && totalPrice>0){
      setAmountTargeted(totalPrice*parseFloat(estimationInputs.subscription)/100);
    } else {
      setAmountTargeted(0);
    }
  }, [estimationInputs, totalPrice]);

  const [subscriptionInputs, setSubscriptionInputs] = useState({
    ticketPrice: '',
    time: '',
    numberSoldTickets: '',
  });
  const [numberOfTicket, setNumberOfTicket] = useState(0);
  const [amountToClub, setAmountToClub] = useState(0);

  const onSubscriptionChange = (e: FormEvent<HTMLInputElement>) => {
    setSubscriptionInputs({ ...subscriptionInputs, [e.currentTarget.name]: e.currentTarget.value });
  };

  useEffect(() => {
    if(parseFloat(subscriptionInputs.ticketPrice)>0 && amountTargeted>0){
      setNumberOfTicket(amountTargeted/parseFloat(subscriptionInputs.ticketPrice));
    } else {
      setNumberOfTicket(0);
    }
  }, [subscriptionInputs.ticketPrice, amountTargeted]);

  useEffect(() => {
    if(parseFloat(subscriptionInputs.ticketPrice)>0 && parseInt(subscriptionInputs.numberSoldTickets)>0){
      setAmountToClub(parseInt(subscriptionInputs.numberSoldTickets)*parseFloat(subscriptionInputs.ticketPrice));
    } else {
      setAmountToClub(0);
    }
  }, [subscriptionInputs.ticketPrice, subscriptionInputs.numberSoldTickets]);

  const [secondaryMarketinputs, setSecondaryMarketInputs] = useState({
    ticketPerMonth: '',
    nbMonth: '',
    amountDedicated: ''
  });
  const [amountDedicatedToClub, setAmountDedicatedToClub] = useState(0);

  const onSecondaryMarketChange = (e: FormEvent<HTMLInputElement>) => {
    setSecondaryMarketInputs({ ...secondaryMarketinputs, [e.currentTarget.name]: e.currentTarget.value });
  };

  useEffect(() => {
    if(
      parseFloat(secondaryMarketinputs.ticketPerMonth)>0 &&
      parseFloat(secondaryMarketinputs.nbMonth)>0 &&
      parseFloat(secondaryMarketinputs.amountDedicated)>0 &&
      parseFloat(subscriptionInputs.ticketPrice)>0
    ){
      setAmountDedicatedToClub(
        parseFloat(
          secondaryMarketinputs.ticketPerMonth
        )*parseInt(secondaryMarketinputs.nbMonth)*parseFloat(
          subscriptionInputs.ticketPrice
        )*parseFloat(
          secondaryMarketinputs.amountDedicated
        )/100
      );
    } else {
      setAmountDedicatedToClub(0);
    }
  }, [secondaryMarketinputs.ticketPerMonth, secondaryMarketinputs.nbMonth, secondaryMarketinputs.amountDedicated, subscriptionInputs.ticketPrice]);

  const [transfertInputs, setTransferInputs] = useState({
    playerOneSale: '',
    playerOneSubRev: '',
    playerTwoSale: '',
    playerTwoSubRev: '',
  });
  const [playerOneAmountSub, setplayerOneAmountSub] = useState(0);
  const [playerOneAmountClub, setplayerOneAmountClub] = useState(0);
  const [playerTwoAmountSub, setplayerTwoAmountSub] = useState(0);
  const [playerTwoAmountClub, setplayerTwoAmountClub] = useState(0);

  const onTransfertChange = (e: FormEvent<HTMLInputElement>) => {
    setTransferInputs({ ...transfertInputs, [e.currentTarget.name]: e.currentTarget.value });
  };

  useEffect(() => {
    if(parseFloat(transfertInputs.playerOneSale)>0 && parseFloat(transfertInputs.playerOneSubRev)>0){
      setplayerOneAmountSub(parseFloat(transfertInputs.playerOneSale)*parseFloat(transfertInputs.playerOneSubRev)/100);
    } else {
      setplayerOneAmountSub(0);
    }
  }, [transfertInputs.playerOneSale, transfertInputs.playerOneSubRev]);

  useEffect(() => {
    if(parseFloat(transfertInputs.playerOneSale)>0 && playerOneAmountSub>0){
      setplayerOneAmountClub(parseFloat(transfertInputs.playerOneSale)-playerOneAmountSub);
    } else {
      setplayerOneAmountClub(0);
    }
  }, [transfertInputs.playerOneSale, playerOneAmountSub]);

  useEffect(() => {
    if(parseFloat(transfertInputs.playerTwoSale)>0 && parseFloat(transfertInputs.playerTwoSubRev)>0){
      setplayerTwoAmountSub(parseFloat(transfertInputs.playerTwoSale)*parseFloat(transfertInputs.playerTwoSubRev)/100);
    } else {
      setplayerTwoAmountSub(0);
    }
  }, [transfertInputs.playerTwoSale, transfertInputs.playerTwoSubRev]);

  useEffect(() => {
    if(parseFloat(transfertInputs.playerTwoSale)>0 && playerTwoAmountSub>0){
      setplayerTwoAmountClub(parseFloat(transfertInputs.playerTwoSale)-playerTwoAmountSub);
    } else {
      setplayerTwoAmountClub(0);
    }
  }, [transfertInputs.playerTwoSale, playerTwoAmountSub]);

  const [total, setTotal] = useState(0);
  useEffect(() => {
    setTotal(amountToClub+amountDedicatedToClub+playerOneAmountClub+playerTwoAmountClub);
  }, [amountToClub, amountDedicatedToClub, playerOneAmountClub, playerTwoAmountClub]);

  return (
    <div className="w-full mt-[46px] lg:mt-16 p-4 flex items-center justify-center bg-gradient-to-bl from-indigo2 via-indigo2 to-indigo3">
      <div className="container mx-auto p-4">
        <div className="grid md:grid-cols-12 gap-6">
          <div className="md:col-span-6 flex flex-col">
            <div className="w-full h-full px-4 py-2 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
              <h5 className="text-xl font-medium text-gray-900 dark:text-white border-b mb-6">1 - ESTIMATION / LAUNCH</h5>
              <div className="flex gap-6 justify-between items-center">
                <div className="flex-1">
                  <label htmlFor="playeOne" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Player 1: price</label>
                  <input
                    type="text"
                    name="playerOne"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none"
                    onChange={ (e) => onEstimationChange(e) }
                  />
                </div>
                <div className="flex-1">
                  <label htmlFor="playerTwo" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Player 2: price</label>
                  <input
                    type="text"
                    name="playerTwo"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none"
                    onChange={ (e) => onEstimationChange(e) }
                  />
                </div>
              </div>
              <div className="bg-green-100 border-green-300 border rounded-lg shadow p-4 mt-6">
                <h5 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">
                  Total price:  <span className="text-green-500">{ formatNumber(totalPrice) } </span>
                </h5>
              </div>
              <div className="mt-6">
                <div>
                  <label htmlFor="subscription" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">%age Subscription</label>
                  <div className="flex">
                    <input
                      type="text"
                      name="subscription"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-l-lg block w-full p-2.5 outline-none"
                      onChange={ (e) => onEstimationChange(e) }
                    />
                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-r-lg">
                      %
                    </span>
                  </div>
                </div>
                <div className="bg-green-100 border-green-300 border rounded-lg shadow p-4 mt-6">
                  <h5 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">
                    Targeted Amount :   <span className="text-green-500">{ formatNumber(amountTargeted) }</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="md:col-span-6 flex flex-col">
            <div className="w-full h-full px-4 py-2 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
              <h5 className="text-xl font-medium text-gray-900 dark:text-white border-b mb-6">2 - SUBSCRIPTION / PRIMARY MARKET</h5>
              <div>
                <div>
                  <label htmlFor="ticketPrice" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ticket price</label>
                  <input
                    type="text"
                    name="ticketPrice"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-l-lg block w-full p-2.5 outline-none"
                    onChange={ (e) => onSubscriptionChange(e) }
                  />
                </div>
                <div className="bg-green-100 border-green-300 border rounded-lg shadow p-4 mt-6">
                  <h5 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">
                    Nb of tickets:   <span className="text-green-500">{ formatNumber(numberOfTicket) }</span>
                  </h5>
                </div>
              </div>
              <div className="flex gap-6 justify-between items-center mt-6">
                <div className="flex-1">
                  <label htmlFor="time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Subscription time (weeks)</label>
                  <input
                    type="text"
                    name="time"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none"
                    onChange={ (e) => onSubscriptionChange(e) }
                  />
                </div>
                <div className="flex-1">
                  <label htmlFor="numberSoldTickets" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nb of sold tickets</label>
                  <input
                    type="text"
                    name="numberSoldTickets"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none"
                    onChange={ (e) => onSubscriptionChange(e) }
                  />
                </div>
              </div>
              <div className="bg-green-100 border-green-300 border rounded-lg shadow p-4 mt-6">
                <h5 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">
                  Income for the club:  <span className="text-green-500">{ formatNumber(amountToClub) } </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-12 gap-6 mt-6">
          <div className="md:col-span-6 flex flex-col">
            <div className="w-full h-full px-4 py-2 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
              <h5 className="text-xl font-medium text-gray-900 dark:text-white border-b mb-6">3 - SECONDARY MARKET</h5>
              <div>
                <label htmlFor="playeOne" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">nb of ticket transaction per month</label>
                <input
                  type="text"
                  name="ticketPerMonth"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none"
                  onChange={ (e) => onSecondaryMarketChange(e) }
                />
              </div>
              <div className="mt-5">
                <label htmlFor="nbMonth" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">nb of months</label>
                <input
                  type="text"
                  name="nbMonth"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none"
                  onChange={ (e) => onSecondaryMarketChange(e) }
                />
              </div>
              <div className="mt-5">
                <label htmlFor="subscription" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">%age of the amount dedicated to the club</label>
                <div className="flex">
                  <input
                    type="text"
                    name="amountDedicated"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-l-lg block w-full p-2.5 outline-none"
                    onChange={ (e) => onSecondaryMarketChange(e) }
                  />
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-r-lg">
                    %
                  </span>
                </div>
              </div>
              <div className="bg-green-100 border-green-300 border rounded-lg shadow p-4 mt-6">
                <h5 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white flex justify-between items-start">
                  <span className="flex-1">Amount dedicated to the club for the perriod:</span>
                  <span className="text-green-500 flex-1 text-right">{ formatNumber(amountDedicatedToClub) } </span>
                </h5>
              </div>
            </div>
          </div>
          <div className="md:col-span-6 flex flex-col">
            <div className="w-full h-full px-4 py-2 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
              <h5 className="text-xl font-medium text-gray-900 dark:text-white border-b mb-6">4 - TRANSFER / DISTRIBUTION</h5>
              <div className="flex gap-6 justify-between items-center mt-6">
                <div className="flex-1">
                  <label htmlFor="playerOneSale" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Player 1: sale</label>
                  <input
                    type="text"
                    name="playerOneSale"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none"
                    onChange={ (e) => onTransfertChange(e) }
                  />
                </div>
                <div className="flex-1">
                  <label htmlFor="playerOneSubRev" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">%age  subs revenue</label>
                  <input
                    type="text"
                    name="playerOneSubRev"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none"
                    onChange={ (e) => onTransfertChange(e) }
                  />
                </div>
              </div>
              <div className="flex flex-wrap md:flex-nowrap gap-4">
                <div className="bg-green-100 border-green-300 border rounded-lg shadow p-4 mt-6">
                  <h5 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">
                    Amount for subs:  <span className="text-green-500">{ formatNumber(playerOneAmountSub) } </span>
                  </h5>
                </div>
                <div className="bg-green-100 border-green-300 border rounded-lg shadow p-4 mt-6">
                  <h5 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">
                    Amount for the club:  <span className="text-green-500">{ formatNumber(playerOneAmountClub) } </span>
                  </h5>
                </div>
              </div>
              <hr className="my-6" />
              <div className="flex gap-6 justify-between items-center">
                <div className="flex-1">
                  <label htmlFor="playerTwoSale" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Player 2: sale</label>
                  <input
                    type="text"
                    name="playerTwoSale"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none"
                    onChange={ (e) => onTransfertChange(e) }
                  />
                </div>
                <div className="flex-1">
                  <label htmlFor="playerTwoSubRev" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">%age  subs revenue</label>
                  <input
                    type="text"
                    name="playerTwoSubRev"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none"
                    onChange={ (e) => onTransfertChange(e) }
                  />
                </div>
              </div>
              <div className="flex flex-wrap md:flex-nowrap gap-4">
                <div className="bg-green-100 border-green-300 border rounded-lg shadow p-4 mt-6">
                  <h5 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">
                    Amount for subs:  <span className="text-green-500">{ formatNumber(playerTwoAmountSub) } </span>
                  </h5>
                </div>
                <div className="bg-green-100 border-green-300 border rounded-lg shadow p-4 mt-6">
                  <h5 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">
                    Amount for the club:  <span className="text-green-500">{ formatNumber(playerTwoAmountClub) } </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-12 gap-6 mt-6">
          <div className="hidden md:flex md:col-span-3"></div>
          <div className="md:col-span-6">
            <div className="w-full px-4 py-2 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
              <h5 className="text-xl font-medium text-gray-900 dark:text-white border-b mb-6">5 - TOTAL INCOME FOR THE CLUB</h5>
              <div className="bg-green-100 border-green-300 border rounded-lg shadow p-4 mt-6">
                <h5 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">
                  Total:  <span className="text-green-500">{ formatNumber(total) } </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
