import React from 'react';
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AiOutlineCopy, AiOutlineDownload } from 'react-icons/ai';

import { successAlert } from '../../../helpers/Utils';
import { Button } from '../../Common/ui';

interface SaveRecovryCodesProps {
  recoveryCodes: string[];
  onFinish: () => void
}
export default function SaveRecovryCodes({ recoveryCodes, onFinish }: SaveRecovryCodesProps) {

  const downloadFile = () => {
    const link = document.createElement('a');
    const content = recoveryCodes.join('\n');;
    const file = new Blob([ content ], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);
    link.download = 'goall_recovery_2fa_codes.txt';
    link.click();
    URL.revokeObjectURL(link.href);
  };

  return (
    <>
      <h3 className="text-lg md:text-xl font-bold text-center dark:text-white">Download recovery codes</h3>
      <p className="text-sm text-center mt-3 dark:text-white">
        If you lose your device, use one of the recovery codes to sign in. Be sure to copy and store them in a secure place. Each code can only be used once.
      </p>

      <div className="mt-6">
        <div className="grid grid-cols-2 gap-4 rounded-xl bg-gray-100 p-6 relative">
          <span className="absolute top-2 right-2">
            <CopyToClipboard
              text={ recoveryCodes.join('\n') }
              onCopy={ () => successAlert('Copied to clipboard') }
            >
              <AiOutlineCopy className="w-6 h-6 cursor-pointer"/>
            </CopyToClipboard>
          </span>
          {
            recoveryCodes.length>0 && recoveryCodes.map((code, index) => (
              <div className="text-center" key={ index }>{ code } </div>
            ))
          }
        </div>
        <span className="text-sm cursor-pointer flex items-center mt-1 dark:text-white" onClick={ downloadFile }>
          <AiOutlineDownload className="mr-1 w-4 h-4" /> Download recovery codes
        </span>
      </div>
      <div className="flex items-center justify-end mt-6">
        <Button
          className="btn btn-primary float-right"
          onClick={ () => onFinish() }
        >
          Done
        </Button>
      </div>
    </>
  );
}
