import React, { ReactElement } from 'react';
import { IoCheckmark } from 'react-icons/io5';

interface StepsProps {
  children?: ReactElement[] | ReactElement;
}
export function Steps( { children }: StepsProps) {

  return (
    <div
      className="relative after:absolute after:inset-x-0 after:top-1/2 after:block after:h-0.5 after:-translate-y-1/2 after:rounded-lg after:bg-gray-100"
    >
      <ol
        className="relative z-10 flex justify-between text-sm font-medium text-gray-500"
      >
        { children }
      </ol>
    </div>
  );
}

interface ItemProps {
  current?: boolean;
  done?: boolean;
  title: string;
  id: number
}
export function StepsItem( { current, done, title, id }: ItemProps) {
  return (
    <li className="flex items-center gap-2 p-2 bg-white dark:text-white dark:bg-indigo2">
      <span
        className={ `flex items-center justify-center h-6 w-6 rounded-full text-center text-[10px] font-bold leading-6 ${current ? 'bg-indigo text-white' : 'bg-gray-100 dark:text-grey'}` }
      >
        {
          done ? <IoCheckmark className="h-4 w-4"/> : id
        }
      </span>
      <span className="hidden sm:block"> { title } </span>
    </li>
  );
}
