import { RequestWrapper } from '../../../helpers/RequestWrapper';
import API from '..';

const BLOG_ENDPOINT = 'blog';

class BlogAPI {
  static getAllPosts = (qs: string, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${BLOG_ENDPOINT}/posts${qs}`)).data, errorHandler
    );
  };

  static getLatestPosts = (limit: number, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${BLOG_ENDPOINT}/latest-posts?limit=${limit}`)).data, errorHandler
    );
  };

  static getPost = (slug: string | undefined, errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${BLOG_ENDPOINT}/post/${slug}/get`)).data, errorHandler
    );
  };

  static getAllCategories = (errorHandler: (error: Error) => void) => {
    return RequestWrapper(
      async () => 
        (await API.get(`${BLOG_ENDPOINT}/categories`)).data, errorHandler
    );
  };
}

export { BLOG_ENDPOINT, BlogAPI };
